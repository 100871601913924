.why-luxoft-training-center {
	&__items-wrapper {
		.splide {
			&__pagination {
				@include media-breakpoint-up(md) {
					display: none;
				}
			}

			&__arrows {
				display: none;

				@include media-breakpoint-up(md) {
					display: block;
					position: absolute;
					bottom: -75px;
				}

				.splide {
					&__arrow {
						background-color: transparent;
						border: 1px solid $black;
						border-radius: 10px;
						width: 45px;
						height: 45px;
						background-image: url(/theme/luxoft-2024/assets/images/icons/splide-arrow.svg);
						background-repeat: no-repeat;
						background-position: center;

						svg {
							display: none;
						}

						&--prev {
							transform: rotate(180deg);
							@include mr(16px);
						}


					}
				}
			}

			&__list {
				.single-item {
					background-color: $gray-90;
					border-radius: 30px;
					@include p(30px);

                    @include media-breakpoint-down(md) {
                        width: 84% !important;
                    }

					.item-icon {
						figure {
							@include m(0);
						}
					}

					.title-section {
						@include mt(30px);

						.item-title {
							font-weight: $font-weight-semibold;
							color: $purple;
							font-size: 22px;
						}
					}

					.text-section {
						@include mt(10px);

						.item-text {
							font-size: 15px;
							color: $dark-gray;
							line-height: 160%;
						}
					}
				}
			}
		}
	}
}
