.about-us__image-with-cols {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    @include media-breakpoint-down(xl) {
        margin-top: 90px;
    }

    @include media-breakpoint-down(md) {
        margin-top: 60px;
        align-items: start;
    }

    &__title {
        margin-bottom: 25px;
        text-align: center;

        @include media-breakpoint-down(md) {
            text-align: start;
            width: 80%;
            margin: 0;
        }
    }

    &__desc {
        padding: 0px 250px;
        margin-bottom: 45px;
        color: $dark-gray;
        text-align: center;

        @include media-breakpoint-down(xl) {
            padding: 0 100px;
        }

        @include media-breakpoint-down(md) {
            text-align: start;
            padding: 0;
            margin-top: 15px;
            margin-bottom: 25px;
        }
    }

    &__container {
        position: relative;
        height: 600px;
        width: 100%;

        @include media-breakpoint-down(xl) {
            height: 400px;
        }

        @include media-breakpoint-down(md) {
            height: 210px;
        }

        figure {
            border-radius: 30px;
            margin: 0;
            height: 100%;
            width: 100%;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 30px;
                @include media-breakpoint-down(md) {
                    border-radius: 20px;
                }
            }
        }

        &__info-banner {
            position: absolute;
            left: 25px;
            bottom: 25px;
            right: 25px;
            background-color: rgba(255, 255, 255, 0.95);
            box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
            border-radius: 25px;
            display: flex;
            padding: 30px 20px;
            height: 165px;

            @include media-breakpoint-down(xl) {
                padding: 35px 25px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &__info {
                padding: 0 20px;

                @include media-breakpoint-down(xl) {
                    padding: 0 10px;
                }

                &__title {
                    margin-bottom: 10px;
                    font-weight: $font-weight-semibold;
                    font-size: 18px;

                    @include media-breakpoint-down(xl) {
                        font-size: 12px;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                    }
                }

                &__desc {
                    color: $dark-gray;
                    font-size: 15px;

                    @include media-breakpoint-down(xl) {
                        font-size: 12px;
                    }
                }
            }

            &--separator {
                width: 1px;
                height: 100%;
                margin: 0px 25px;
                background-color: $gray-e6e6;
                @include media-breakpoint-down(xl) {
                    margin: 0 5px;
                }
            }
        }
    }

    & > a {
        align-self: center;
    }
}

#splider-about-us-info-banner {
    display: none;
    width: 100%;

    @include media-breakpoint-down(md) {
        display: block;
    }
    .splide__track {
        margin-right: -20px;
        margin-left: -20px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .about-us__image-with-cols__container__info-banner-mobile__card {
        padding: 20px;
        border-radius: 20px;
        border: 0.08em solid $gray-e6e6;
        height: 100%;

        &__desc {
            color: $dark-gray;
            line-height: 160%; /* 24px */
            letter-spacing: -0.3px;
        }
    }
}