.careers-with-impact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 160px;
        text-align: center;
        row-gap: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-down(xxl) {
            padding: 0 230px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 40px;
        }

        @include media-breakpoint-down(md) {
            text-align: left;
            padding: 0 20px;
            font-size: 16px;
        }

        h2 {
            margin: 0;
            color: $text-rich-black;
        }

        p {
            color: $gray-66;
            margin: 0;
            font-size: 20px;

            @include media-breakpoint-down(xxl) {
                font-size: 18px;
            }

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 160%;
                font-weight: $font-weight-normal;
            }
        }

        button {
            font-size: $font-size-14;
            text-transform: none;
        }
    }
}
