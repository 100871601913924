.blog-post {
    @include mt(88px);

    &__right-column {
        &.scrolled {
            .blog-right-column-separator {
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }

        &.no-authors {
            .blog-post__right-column--authors {
                border-bottom: none;
            }
        }

        &--authors {
            border-bottom: 1px solid $gray-c3;
            @include mb(1.7rem);

            .item {
                @include mb(4);

                .image {
                    width: 60px;
                    height: 60px;
                    position: relative;
                    overflow: hidden;
                    @include mb(2);

                    img {
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        width: 100%;
                    }
                }

                .text {
                    .author-name {
                        @include mb(1);
                        color: $gray-20;
                        font-size: 18px;
                        font-weight: $font-weight-semibold;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                    }

                    .author-title {
                        color: $dark-gray;
                        font-size: 14px;
                        font-weight: $font-weight-normal;
                    }
                }
            }
        }

        .related-content {
            &.fixed {
                position: fixed;
                top: 175px;

                @include media-breakpoint-up(md) {
                    width: 256px;
                }

                @include media-breakpoint-up(xl) {
                    width: 287px;
                }
            }

            &__title {
                @include mb(1rem);

                p {
                    font-weight: $font-weight-semibold;
                    color: $dark-gray-15;
                }
            }

            &__items {
                .item {
                    position: relative;
                    @include mb(1.5rem);

                    &.html-code {
                        display: none;
                    }

                    &--image {
                        width: 100%;
                        height: 116px;
                        @include border-radius(14px);
                        position: relative;
                        overflow: hidden;

                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }

                    &--title {
                        @include mt(10px);

                        a {
                            font-weight: $font-weight-semibold;
                            font-size: 18px;
                            color: $gray-20;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    &__mobile-header {
        z-index: 5;
        position: fixed;
        top: 88px;
        width: 100%;
        transition: all .3s ease;
        opacity: 0;
        visibility: hidden;
        background-color: $white;
        border-bottom: 1px solid $gray-e6e6;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .header-title-social-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 70px;

            .post-title-wrapper {
                height: 18px;
                overflow: hidden;

                .post-title {
                    font-size: 14px;
                    font-weight: $font-weight-semibold;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    text-wrap: balance;
                    display: -webkit-box;
                }
            }

            .post-social {
                ul {
                    @include mb(0);
                    @include pl(0);
                    display: flex;
                    gap: 15px;
                    align-items: center;

                    li {
                        list-style: none;

                        p {
                            font-size: 15px;
                        }
                    }
                }
            }
        }

        &.fixed {
            visibility: visible;
            opacity: 1;
        }
    }

    &__content {
        &--header {
            @include mt(1.5rem);

            .post-title-wrapper {
                @include mb(1.5rem);

                .post-title {
                    font-size: 35px;
                    font-weight: $font-weight-semibold;

                    @include media-breakpoint-up(md) {
                        font-size: 50px;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: 55px;
                    }
                }
            }

            .post-author-social-wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;

                @include media-breakpoint-up(md) {
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    gap: 0;
                }

                .post-author {
                    p {
                        font-size: 15px;
                        color: $dark-gray;

                        b {
                            color: $gray-30;
                        }
                    }
                }

                .post-social {
                    ul {
                        @include mb(0);
                        @include pl(0);
                        display: flex;
                        gap: 15px;
                        align-items: center;

                        li {
                            list-style: none;

                            p {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }

        .text-content {
            p {
                font-size: 18px;
                line-height: 25.2px;
                color: $gray-30;
            }

            ol {
                font-size: 15px;
                line-height: 24px;
            }

            .blog-content-separator {
                display: none;
                width: 100%;

                &.small {
                    height: 440px;
                }

                &.medium {
                    height: 480px;
                }

                &.large {
                    height: 530px;
                }
            }
        }

        .blog-authors {
            background-color: $white;
            height: auto;
            max-width: 100vw;
            @include pt(2rem);
            position: relative;
            width: 100%;

            .author-wrapper {
                border-top: 1px solid $gray-c3;

                &__content {
                    display: flex;
                    flex-direction: column;
                    @include mt(2rem);

                    @include media-breakpoint-up(md) {
                        display: -webkit-box;
                    }

                    .author-image {
                        width: 120px;
                        height: 120px;
                        position: relative;
                        overflow: hidden;
                        @include mb(20px);
                        border-radius: 20px;

                        @include media-breakpoint-up(md) {
                            @include mb(0);
                        }

                        .picture {
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                            width: 100%;
                        }
                    }

                    .author-content {
                        text-wrap: balance;

                        @include media-breakpoint-up(md) {
                            @include ml(40px);
                            width: 73%;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 76%;
                        }

                        @include media-breakpoint-up(xl) {
                            width: 79%;
                        }

                        @include media-breakpoint-up(xxl) {
                            width: 82%;
                        }

                        .author-name {
                            color: $dark-gray-15;
                            font-size: 22px;
                            font-weight: $font-weight-bold;
                            @include mb(8px);
                            display: flex;
                            gap: 10px;
                            align-items: center;
                        }

                        .author-title {
                            font-size: 14px;
                            @include mb(16px);

                            @include media-breakpoint-up(md) {
                                @include mb(24px);
                            }
                        }

                        .author-title,
                        .author-bio {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .blog-lead-paragraph {
            p {
                font-size: 22px;
                font-weight: $font-weight-semibold;
            }
        }

        .cta-banner-section {
            min-height: 232px;
            position: relative;

            .row {
                .col-md-6 {
                    @include media-breakpoint-up(md) {
                        &:first-child {
                            @include pr(0);
                        }

                        &:nth-child(2) {
                            @include pl(0);
                        }
                    }
                }
            }

            &__content {
                min-height: 190px;
                @include px(4);
                @include pt(24px);
                @include pb(32px);
                background-color: $bright-purple-90;
                border-radius: 20px;

                @include media-breakpoint-up(md) {
                    min-height: 232px;
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                    border-top-right-radius: initial;
                    border-bottom-right-radius: initial;
                }

                .cta-banner-text {
                    @include mb(24px);
                    @include mt(10px);
                    font-weight: $font-weight-semibold;
                }

                .btn-container {
                    display: flex;

                    a {
                        width: auto;
                        text-transform: uppercase;
                    }
                }
            }

            &__image {
                position: relative;
                height: 100%;

                figure {
                    @include m(0)
                }

                img {
                    border-top-right-radius: 1rem;
                    border-bottom-right-radius: 1rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .blog-photo-banner {
            &__figure {
                margin-bottom: 0;
                height: 250px;
                width: 100%;

                @include media-breakpoint-up(md) {
                    height: 310px;
                }

                @include media-breakpoint-up(xl) {
                    height: 400px;
                }

                &--img {
                    object-fit: cover;
                    border-radius: 30px;
                    height: 100%;
                    width: 100%;

                    @include media-breakpoint-down(md) {
                        border-radius: 20px;
                    }
                }
            }
        }
    }

    .related-content-border {
        border-top: 1px solid $gray-c3;
        @include mt(3rem);
    }

    &__related-content {
        @include pt(5);
        @include pb(4);

        @include media-breakpoint-up(md) {
            @include pt(8);
        }

        .row {
            .col {
                flex: auto;

                @include media-breakpoint-up(md) {
                    flex: 1 0 0%;
                }

                &:not(:last-child) {
                    .blog-post__standard-item {
                        border-bottom: 1px solid $gray-e6e6;
                        @include pb(20px);
                    }
                }
            }
        }

        &--title {
            @include mb(2rem);

            @include media-breakpoint-up(md) {
                text-align: center;
                @include mb(2.5rem);
            }

            h2 {
                font-size: 35px;
                color: $gray-20;
                line-height: 36.4px;
                font-weight: $font-weight-semibold;

                @include media-breakpoint-up(md) {
                    font-size: 50px;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 55px;
                }

                @include media-breakpoint-up(xxl) {
                    font-size: 60px;
                }
            }
        }

        @include blog-item();
    }
}

.single-event {
    &__our-speakers {
        @include p(10px 20px 45px);

        @include media-breakpoint-up(md) {
            @include p(10px 0 45px);
        }

        .row > * {
            @include pr(0);
            @include pl(0);
        }

        &--title {
            @include media-breakpoint-up(md) {
                text-align: center;
            }
        }

        .html-code {
            display: none;
        }

        .our-speakers-mobile {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        .our-speakers-desktop {
            display: none;

            @include media-breakpoint-up(md) {
                display: flex;
            }
        }

        &--item--container {
            @include media-breakpoint-up(md) {
                &:nth-child(odd) {
                    @include pr(10px);
                }

                &:nth-child(even) {
                    @include pl(10px);
                }
            }
        }

        &--item {
            border: 1px solid $gray-e6e6;
            border-radius: 30px;
            @include p(1rem 1.2rem);
            width: 84% !important;

            @include media-breakpoint-up(md) {
                @include p(2rem 1.5rem);
                width: auto !important;
            }

            &--name-title {
                display: flex;
                align-content: center;
                justify-content: space-between;

                .speaker-name-title {
                    width: 84%;

                    @include media-breakpoint-up(xl) {
                        width: 90%;
                    }

                    .speaker-name {
                        color: $dark-gray-15;
                        @include mb(4px);
                    }

                    .speaker-title {
                        color: $dark-gray;
                    }
                }
            }

            &--bio {
                @include media-breakpoint-up(md) {
                    display: -webkit-box;
                }

                .speaker-img {
                    img {
                        border-radius: 10px;
                        width: 105px !important;
                    }
                }

                .speaker-bio {
                    @include mt(3);

                    @include media-breakpoint-up(md) {
                        @include mt(0);
                        @include ml(25px);
                        width: 65%;
                    }

                    @include media-breakpoint-up(xl) {
                        width: 67%;
                    }

                    @include media-breakpoint-up(xxl) {
                        width: 78%;
                    }

                    p {
                        color: $dark-gray;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

.blogs-listing {
    @include blog-item();

    .col-12 {
        @include media-breakpoint-up(md) {
            @include pr(8px);
            @include pl(8px);
        }

        &:not(:last-child) {
            .blog-post__standard-item {
                border-bottom: 1px solid $gray-e6e6;
                @include pb(20px);
            }
        }
    }

    &__no-vacancies {
        @include p(70px 0 40px);

        p {
            line-height: 140%;
            font-size: 15px;

            @include media-breakpoint-up(md) {
                font-size: 18px;
            }
        }
    }

    .blog-post {
        &__standard-item {
            @include mb(24px);
        }
    }

    &__cta {
        @include mt(6);

        @include media-breakpoint-up(md) {
            @include mt(10);
        }
    }

    &__upcoming-courses {
        @include media-breakpoint-up(md) {
            @include mt(6);
        }

        .splide {
            &__list {
                .single-course {
                    @include mr(20px !important);
                }
            }
        }
    }
}

.blogs-filters-chips-wrapper {
    @include mb(7);

    .blogs-filters {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;

        @include media-breakpoint-up(xl) {
            gap: initial;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        &__apply-btn-mobile {
            .btn-normal {
                width: 100%;
            }

            @media (width > 600px) {
                display: none;
            }
        }

        &__search-form {
            display: flex;
            background-color: $white;
            box-sizing: border-box;
            font-size: 14px;
            gap: 10px;
            border-radius: 15px;
            border: 1px solid $gray-e6e6;
            @include p(4px 6px);
            height: 57px;
            width: 100%;

            @include media-breakpoint-up(xl) {
                width: auto;
            }

            input {
                color: black;
                border: none;
                width: 92%;
                line-height: normal;

                @include media-breakpoint-up(xl) {
                    width: 500px;
                }

                &:focus-visible {
                    border: none;
                    outline: none;
                }
            }

            .btn-normal {
                display: flex;
                align-items: center;
                justify-content: center;
                @include p(0px 13px);
                width: auto;
            }
        }

        &__topics-form {
            display: flex;
            align-items: center;
            gap: 10px;
            border-radius: 15px;
            background-color: $white;
            width: 50%;
            height: 64px;

            @include media-breakpoint-up(md) {
                border-radius: 20px;
            }

            @include media-breakpoint-up(xl) {
                width: 45%;
            }

            @include media-breakpoint-up(xxl) {
                width: 35%;
            }

            > input {
                display: flex;
                border: none;
                padding: 15px;
                color: $black;
                width: 100%;
                height: 55px;
                cursor: pointer;

                @include media-breakpoint-down(xl) {
                    min-width: 250px;
                }

                @include media-breakpoint-down(md) {
                    height: auto;
                    padding: 0;
                }

                &:focus-visible {
                    border: none;
                    outline: none;
                }
            }

            > .dropdown {
                @include dropdown-search();
            }

            .btn-normal {
                padding-top: 13px;
                height: 55px;
                min-width: 96px;
                border-radius: 15px;
                text-transform: capitalize;
                display: none;

                @media (width > 600px) {
                    display: block;
                }
            }
        }

        &__chips {
            @include filters-chips();
        }
    }
}
