@mixin filter-input-search() {
    .filter-input-search {
        border: 1px solid $gray-b3b3;
        display: flex;
        @include p(10px 15px);
        border-radius: 15px;
        width: 92%;
        height: 54px;
        align-items: center;
        gap: 5px;

        @media (width > 750px) {
            width: 95%;
        }

        input {
            border: none;
            height: 30px;
            color: $black;
            flex-grow: 1;
            flex-shrink: 1;
            font-size: 15px;
            caret-color: $orange;

            &:focus,
            &:active {
                border: none;
                outline: none;
            }
        }

        button {
            border: none;
            background-color: transparent;
            @include p(0);
        }
    }
}
