.category-page {

	&__content {
		@include pt(40px);
		@include pb(3.5rem);

		@include media-breakpoint-up(lg) {
			@include pt(60px);
		}

		.category-course__header {
			width: 100%;
			display: flex;
			justify-content: flex-end;

			.courses-counter {
				padding: 9px 12px;
				background-color: $gray-90;
				display: flex;
				gap: 8px;
				border-radius: 10px;
				align-items: center;

				p {
					font-size: 13px;
					color: $dark-gray-15;
				}
			}
		}

		.category-course__list {
			display: grid;
			grid-template-columns: 1fr;
			gap: 15px;
			list-style: none;
			padding-left: 20px;
			padding-right: 20px;

			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(2, 1fr);
				padding: 0;
				gap: 20px;
			}

			@include media-breakpoint-up(xl) {
				grid-template-columns: repeat(3, 1fr);
			}

			.category-course__item {
				gap: 15px;
				background: #FFFFFF;
				overflow: hidden;
				border: 1.3px solid #E6E6E6;
				border-radius: 30px;
				height: calc(100% - var(--course__warp-margin) * 2);
				position: relative;

				&:hover {
					background: #FFFFFF;
					box-shadow: 0px 5px 30px rgba(21, 21, 21, 0.15);
				}
			}
		}
	}
}

.course-tile {
	--course__warp-margin: 20px;

	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: flex-start;
	text-decoration: none;
	padding: var(--course__warp-margin);
	width: 100%;
	height: 100%;

	a {
		text-decoration: none;
	}

	.course__body {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		gap: 15px;

		.course__header {
			.course__title {
				font-weight: $font-weight-semibold;
				font-size: 18px;
				line-height: 130%;
				align-items: center;
				letter-spacing: -0.02em;

				a {
					color: $dark-gray-15;
				}
			}
		}

		.course__description {
			font-size: 14px;
			line-height: 140%;
			letter-spacing: -0.02em;
			color: $dark-gray;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			overflow: hidden;
		}

		.course__footer {
			.course__labels {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				gap: 10px;
				list-style: none;
				padding: 0;
				margin: 0;

				.label {
					font-weight: $font-weight-normal;
					font-size: 13px;
					line-height: 140%;
					display: flex;
					align-items: center;
					letter-spacing: -0.02em;
					color: $dark-gray;
					white-space: nowrap;
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 5px 10px 5px 5px;
					gap: 6px;
					height: 34px;
					background: $gray-90;
					border-radius: 10px;

					&__new {
						background: $bright-purple-90;
						color: #5F249F;
					}
				}
			}
		}
	}
}
