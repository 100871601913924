.courses-list {
    .listing-hero {
        &__bg {
            background-image: linear-gradient(rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)), url('/theme/luxoft-2024/assets/images/others/it-courses.jpg') !important;
        }
    }
}

.course-page {
	margin-top: 90px;

    hr {
        color: $gray-e6e6;
    }
}

.course-content {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	flex-direction: row;
	gap: 20px;

	@include media-breakpoint-up(md) {
		grid-template-columns: 1fr minmax(auto, 340px);
		grid-template-rows: auto 1fr;
	}

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
    }

    .table {
        border-color: transparent;
        color: $dark-gray;

        > :not(caption) > * > * {
            @include p(0.2rem);
        }
    }

	&__preview {
		display: flex;
		flex-direction: column;

		@include media-breakpoint-up(md) {
			@include mb(6)
		}

		.course-title {
			font-weight: $font-weight-bold;
			font-size: 25px;
			line-height: $line-height-md;
			color: $text-rich-black;

            @include media-breakpoint-up(md) {
                font-size: 30px;
            }
		}

		.course-subtitle {
			font-weight: $font-weight-normal;
			font-size: 18px;
			line-height: 150%;
			color: $dark-gray;
		}

		.course-shortinfo {
			display: flex;
			flex-direction: row;
			gap: 12px;
			@include mt(3);
			@include mb(3);

			.course-item__date {
				font-size: 15px;
			}
		}
	}

	&__detail {
		display: flex;
		flex-direction: column;

		.course-content__block {
			.block__title {
				font-weight: $font-weight-semibold;
                font-size: 22px;
				color: $text-rich-black;
				@include mb(24px);

                @include media-breakpoint-up(md) {
                    font-size: 25px;
                }
			}

			.block__text {
                font-size: 18px;
				color: $dark-gray;
				line-height: 150%;

				ul {
					padding-left: 1rem;
                    font-size: 18px;
					display: grid;

                    ol {
                        display: grid;
                    }

					li {
						list-style-type: none;
						display: inline-flex;

						&::before {
							content: url("/theme/luxoft-2024/assets/images/icons/bullet-purple-square.png");
							color: $purple;
							display: inline-flex;
							width: 0;
							@include ml(-1rem);
							@include mr(24px);
						}
					}
				}
			}
		}
	}

	&__aside {
		gap: 20px;
		display: flex;
		flex-direction: column;

		@include media-breakpoint-up(md) {
			grid-row: span 2;
		}

		.aside__block {
			overflow: hidden;
			border: 1.3px solid $gray-e6e6;
			border-radius: 30px;
			padding: 25px 30px;

			&_fixed {
				@include media-breakpoint-up(xl) {
					position: sticky;
					top: 90px;
				}
			}
		}
	}

	&__course-offer {
		display: flex;
		flex-direction: column;
		gap: 10px;

        .active-schedule {
            display: flex;
            gap: 18px;

            span,
            label {
                font-size: 16px;
                color: $dark-gray;
            }

            label {
                font-weight: $font-weight-semibold;
            }
        }

        input[type='radio'] {
            accent-color: $purple;
            width: 20px;
            cursor: pointer;
        }

        button,
        a {
            font-size: 14px;
        }

        b,
        p {
            font-size: 16px;
        }

        b {
            font-weight: $font-weight-semibold;
        }

        p {
            color: $dark-gray;
        }

		dl {
			display: grid;
			grid-template-columns: auto 1fr;
			margin-bottom: 0;
			gap: 10px;

            dt {
                font-weight: $font-weight-semibold;
                font-size: 16px;
            }

			dt:first-letter {
				text-transform: uppercase;
			}

			dd {
				text-align: right;
                @include mb(0);

				&, a {
					text-decoration: none;
					color: $dark-gray;
                    font-size: 16px;
				}

				&.offer-price {
					font-weight: $font-weight-bold;
					font-size: 36px;
					color: $purple;
				}
			}
		}
	}

    hr {
        @include m(2rem 0);
    }
}

.labels {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	flex-wrap: wrap;
	gap: 12px;
	list-style: none;
	padding: 0;
	margin: 0;

	.label {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 5px 10px;
		gap: 6px;
		background: $gray-90;
		border-radius: 10px;
		flex-wrap: nowrap;

		.label-text {
			font-size: 13px;
		}

		&--format {
			background-color: $bright-purple-90;
			color: $purple;
		}

		&--language {
			background-color: $orange-ff;
			color: $oragne-f1;
		}
	}
}

.trainer {
	gap: 30px;

	&__header {
		display: flex;
		flex-direction: row;
		gap: 40px;
	}

	&__image {
		@include m(0);

		img {
            width: 120px;
            border-radius: 20px;
        }
	}

	&__descr {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	&__labels {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 0;

		.label {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 5px 10px 5px 5px;
			gap: 6px;
			height: 34px;
			background: $gray-90;
			border-radius: 10px;
		}
	}

	&__name {
		font-weight: $font-weight-bold;
		line-height: $line-height-md;
		color: $text-rich-black;
        font-size: 22px;

        @include media-breakpoint-up(md) {
            font-size: 25px;
        }
	}

    &__position {
        color: $dark-gray;
        font-size: 16px;
    }

	&__text {
		font-size: 18px;
		line-height: 150%;
		color: $dark-gray;
	}
}

.course-offer {
	&__remark {
		font-size: 12px;
        color: $dark-gray;

        a {
            font-size: 14px;
        }
	}

	button,
    a {
		width: 100%;
		@include mt(2);
	}

    hr {
        @include m(12px 0);
    }
}

.certificate-banner {
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: flex-start;
	align-items: center;
	@include my(2);

	&__text {
		color: $purple;
		font-weight: $font-weight-semibold;
		font-size: 16px;
		line-height: 150%;
	}
}

.courses-list {
    &__related {
        &.desktop {
            display: flex;
            gap: 15px;

            @include media-breakpoint-down(md) {
                display: none;
            }

            .courses-list {
                &__item {
                    flex-grow: 1;
                    flex-basis: 0;
                }
            }
        }

        &.mobile {
            @include media-breakpoint-up(md) {
                display: none;
            }

            .splide {
                &__slide {
                    width: 84% !important;
                }
            }
        }

        .courses-list {
            &__item {
                .course {
                    &__title {
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        min-height: 70px;

                        @include media-breakpoint-up(xl) {
                            -webkit-line-clamp: 2;
                            min-height: 48px;
                        }
                    }
                }
            }
        }


    }

    &__item {
        border: 1.3px solid $gray-e6e6;
        border-radius: 30px;
        position: relative;

        &:hover {
            box-shadow: 0px 5px 30px rgba(21, 21, 21, 0.15);
        }
    }

	&__content {
		@include pt(40px);
		@include pb(3.5rem);

		@include media-breakpoint-up(xl) {
			@include pt(60px);
		}

	}

	&__warp {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin: 0;
		padding: 0;

		.courses-list__item {
			padding: 20px;
			border: 1.3px solid $gray-e6e6;
			border-radius: 30px;

            @include media-breakpoint-up(md) {
                padding: 25px;
            }
		}
	}

	.course-item {
		display: grid;
		gap: 15px;
		grid-template-columns: 1fr;

		@include media-breakpoint-up(md) {
            gap: 30px;
			grid-template-columns: 1fr 1fr 1fr;
		}

		@include media-breakpoint-up(xl) {
			grid-template-columns: 235px minmax(0, 2fr) minmax(0, 271px);
		}

		a {
			text-decoration: none;
		}

		&__img-warp {
			overflow: hidden;
			background: transparent;
			display: flex;
			justify-content: center;
			width: 235px;
    		height: 235px;

			@include media-breakpoint-down(md) {
				width: 100%;
			}

			.course-item__img {
				width: fit-content;
				object-fit: cover;
				object-position: center;
				border-radius: 20px;

				@include media-breakpoint-down(md) {
					width: 100%;
				}
			}
		}

		&__header {
			display: flex;
			flex-direction: column;
			gap: calc(30px / 2);

			&-title {
				font-weight: $font-weight-semibold;
				font-size: 20px;
				line-height: 130%;
				align-items: center;
				letter-spacing: -0.02em;

				@include media-breakpoint-up(md) {
					font-size: 22px;
				}

				&, &>a {
					color: $dark-gray-15;
				}
			}

			&-subtitle {
				font-size: 14px;
				color: $dark-gray;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				display: -webkit-box;
				overflow: hidden;
			}
		}

		&__body {
			gap: 15px;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1 1 100%;

			&-shortinfo {
				display: flex;
				flex-direction: column;
				justify-content: start;
				gap: 10px;

				@include media-breakpoint-up(xl) {
					flex-direction: row;
					align-items: center;
					gap: 30px;
				}

				.course-item__date {
					font-size: 15px;
				}
			}

			.tags {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: start;
				flex-wrap: wrap;
				gap: 10px;
				list-style: none;
				padding: 0;
				margin: 0;

				.tag {
					border: solid 1px $gray-90;
					border-radius: 10px;
					@include p(8px 10px 8px);
					display: flex;

					.tag-link {
						font-size: 13px;
						color: $dark-gray;
					}
				}
			}

			.labels {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: start;
				flex-wrap: wrap;
				gap: 10px;
				list-style: none;
				padding: 0;
				margin: 0;

				.label {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 5px;
					gap: 4px;
					background: $gray-90;
					border-radius: 10px;
					flex-wrap: nowrap;

					.label-text {
						font-size: 13px;
					}

					&--format {
						background-color: $bright-purple-90;
						color: $purple;
					}

					&--language {
						color: $oragne-f1;
						background-color: $orange-ff;
					}
				}
			}
		}

		&__description {
			&-list {
				display: inline-flex;
				flex-wrap: wrap;
				gap: 10px;
				margin: 0;

				@include media-breakpoint-up(md) {
					display: grid;
					grid-template-rows: auto;
					grid-template-columns: 1fr 1fr;
				}

				@include media-breakpoint-up(xl) {
					grid-template-columns: 1fr 2fr 1fr;
				}

				.description-item {
					display: inline-flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					flex: initial;
					gap: 5px;

					@include media-breakpoint-up(md) {
						flex: 1 1 0;
					}

					&--duration {
						order: unset;
					}

					&--labels {
						flex: initial;

						@include media-breakpoint-up(md) {
							order: 2;
							grid-column: span 2;
						}

						@include media-breakpoint-up(xl) {
							order: 1;
							grid-column: unset;
						}

						.description-item__details {
							background: transparent;
						}
					}

					&--price {
						order: 2;

						@include media-breakpoint-up(md) {
							order: unset;
						}

						@include media-breakpoint-up(xl) {
							order: 2;
						}
					}

					&__term {
						color: $dark-gray;
						display: none;
						font-size: 14px;

						@include media-breakpoint-up(md) {
							display: inline-block;
						}
					}

					&__details {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 6px;
						border-radius: 10px;
                        margin-top: 8px;
					}
				}
			}
		}

		&__actions {
			gap: 10px;
			display: flex;
			flex-direction: column;
			justify-content: start;

			@include media-breakpoint-up(md) {
				border-left: solid 1px $gray-e6e6;
				padding-left: 30px;
			}

			.btn-primary,
			a {
				font-size: 14px;
			}

			.btn-label {
				display: none;

				@include media-breakpoint-up(md) {
					display: block;
				}
			}

			.b2b-title {
				font-weight: $font-weight-semibold;
				color: $dark-gray-15;
				font-size: 14px;
			}

			.b2b-subtitle {
				color: $dark-gray;
				font-size: 12px;
			}
		}
	}
}


.courses-filter-chips-wrapper {
	@include mb(7);

	.courses-filter {
		display: flex;
		flex-direction: column-reverse;
		gap: 10px;

		@include media-breakpoint-up(xl) {
			gap: initial;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		&__apply-btn-mobile {
			.btn-normal {
				width: 100%;
			}

			@media (width > 650px) {
				display: none;
			}
		}

		&__search-form {
			display: flex;
			background-color: $white;
			box-sizing: border-box;
			font-size: 14px;
			gap: 10px;
			border-radius: 15px;
			border: 1px solid $gray-e6e6;
			@include p(4px 6px);
			height: 57px;
			width: 100%;

			@include media-breakpoint-up(xl) {
				width: auto;
			}

			input {
				color: black;
				border: none;
				width: 92%;

				@include media-breakpoint-up(xl) {
					width: 500px;
				}

				&:focus-visible {
					border: none;
					outline: none;
				}
			}

			.btn-normal {
				display: flex;
				align-items: center;
				justify-content: center;
				@include p(0px 13px);
				width: auto;
			}
		}

		&__categories-form {
			display: flex;
			align-items: center;
			gap: 10px;
			border-radius: 15px;
			background-color: $white;
			width: 50%;
			height: 64px;

			@include media-breakpoint-up(md) {
				border-radius: 20px;
			}

			@include media-breakpoint-up(xl) {
				width: 45%;
			}

			@include media-breakpoint-up(xxl) {
				width: 35%;
			}

			>input {
				display: flex;
				border: none;
				padding: 15px;
				color: $black;
				width: 100%;
				height: 55px;
				cursor: pointer;

				@include media-breakpoint-down(xl) {
					min-width: 250px;
				}

				@include media-breakpoint-down(md) {
					height: auto;
					padding: 0;
				}

				&:focus-visible {
					border: none;
					outline: none;
				}
			}

			>.dropdown {
                @include dropdown-search();
			}

			.btn-normal {
				padding-top: 13px;
				height: 55px;
				min-width: 96px;
				border-radius: 15px;
				text-transform: capitalize;
				display: none;

				@media (width > 650px) {
					display: block;
				}
			}
		}

		&__chips {
            @include filters-chips();
		}
	}
}

.tag__list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 12px;

	@include media-breakpoint-up(md) {
		gap: 20px;
	}

	.tag {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 20px 35px;
		gap: 10px;
		background-color: $gray-90;
		border: solid 2px $gray-90;
		border-radius: 24px;
		font-size: 13px;
		line-height: $line-height-130;
		color: $text-rich-black;
		text-decoration: none;
		text-transform: lowercase;

		&.show-more {
			cursor: pointer;
			background-color: transparent;
		}
	}
}
