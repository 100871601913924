.homepage {
    position: relative;

    &__search-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 45px;
        @include mt(45px);
        position: relative;

        @include media-breakpoint-down(md) {
            align-items: start;
        }

        &--wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 760px;
            }

            .search-form {
                display: flex;
                background-color: $white;
                padding: 10px;
                border-radius: 20px;
                box-sizing: border-box;
                border: 1.5px solid transparent;
                width: 100%;
                font-size: 14px;

                @include media-breakpoint-up(md) {
                    width: 560px;
                }

                &.showing-results {
                    @include media-breakpoint-up(md) {
                        border-radius: 20px 20px 0 0;
                    }
                }

                &--icon {
                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }

                &--input {
                    border: none;
                    padding: 15px;
                    color: black;
                    width: 90%;
                    border-radius: 15px;
                    caret-color: $orange;

                    @include media-breakpoint-up(md) {
                        width: 80%;
                    }

                    &:focus-visible {
                        border: none;
                        outline: none;
                    }
                }

                button {
                    margin-left: auto;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }

            .popular-search {
                color: $gray-500;
                gap: 5px;
                flex-wrap: wrap;

                &__label {
                    font-size: 14px;
                    margin-right: 10px;
                    display: none;

                    @include media-breakpoint-up(md) {
                        display: initial;
                    }
                }

                &__role {
                    cursor: pointer;
                    background-color: $gray-21;
                    @include p(6px 15px);
                    border-radius: 10px;

                    &:hover {
                        background-color: $gray-30;

                        a {
                            color: $white;
                        }
                    }

                    a {
                        display: block;
                        color: $gray;
                        font-size: 14px;
                        text-decoration: none;

                        @include media-breakpoint-down(md) {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    &__banner-section {
        height: 50vh;
        min-height: 700px;

        &--wrapper {
            @include pt(120px);
            margin-bottom: 60px;
            z-index: 6;
            position: relative;

            @include media-breakpoint-up(md) {
                @include pt(168px);
            }

            .home-title {
                text-align: left;
                @include mb(20px);

                @include media-breakpoint-up(md) {
                    text-align: center;
                }

                @include media-breakpoint-up(xl) {
                    width: 80%;
                    @include m(0 auto 20px);
                }

                @include media-breakpoint-up(xxl) {
                    width: 84%;
                }
            }

            .home-subheading {
                color: $gray;
                text-align: left;

                @include media-breakpoint-up(md) {
                    text-align: center;
                }
            }
        }
    }

    &__banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        width: 100%;
        height: calc(100% + 40px);
    }
}

.keyword-search-panel {
    position: fixed;
    transform: translateY(100%);
    width: 100%;
    height: 100vh;
    transition: opacity 0.2s ease, transform 0.2s ease;
    top: 0;
    background-color: $white;
    z-index: 1001;
    left: 0;

    &.show {
        opacity: 1;
        transform: translateY(0);

        @include media-breakpoint-up(md) {
            opacity: 0;
            transform: translateY(100%);
        }
    }

    &__close {
        @include p(0 20px);
    }

    &__results {
        @include p(0 30px);
        height: 70vh;
        overflow-x: hidden;

        &.item-selected {
            height: 70dvh;
        }

        .nothing-found {
            width: 100%;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            gap: 20px;

            p {
                font-size: 14px;
            }
        }

        ul {
            list-style: none;
            @include pl(0);

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 0;

                a {
                    color: $dark-gray-15;
                    text-decoration: none;
                }
            }
        }
    }

    &__input-section {
        display: flex;
        @include p(0 20px);
        gap: 10px;
        align-items: center;
        height: 54px;

        .back-btn {
            border: none;
            background-color: transparent;
            flex: 0 0 32px;
            @include p(0);
        }

        @include filter-input-search();
    }
}

.home-search-results {
    width: 560px;
    background-color: $white;
    height: 0;
    opacity: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    top: 74px;
    transition: all 0.2s ease;
    border-top: 1px solid $gray-90;
    box-shadow: 0 35px 50px -30px rgba(46, 46, 46, 0.44);
    -webkit-box-shadow: 0 35px 50px -30px rgba(46, 46, 46, 0.44);
    overflow: hidden;

    @include media-breakpoint-down(md) {
        display: none;
    }

    &.active {
        height: 272px;
        opacity: 1;
    }

    &__wrapper {
        overflow-y: auto;
        height: 100%;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-999;
            outline: 1px solid $gray-999;
        }

        .result-item {
            height: 54px;
            @include pl(19px);
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 5px;
            text-decoration: none;

            &:hover {
                background-color: $gray-f9;
            }

            p {
                font-size: 14px;
                color: $dark-gray-15;
            }
        }

        .nothing-found {
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            gap: 20px;

            p {
                font-size: 14px;
            }
        }

    }
}

body {
    &:has(.keyword-search-panel.show) {
        overflow-y: hidden;
    }
}
