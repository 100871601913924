.overlay {
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 98;
	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	display: block;
	opacity: 0;
	transform: translateX(-10000px);
	transition: opacity 1s;

	&.show {
		transform: translateX(0);
		opacity: 1;
	}

    &.overlay-mobile {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}


.modal {
	display: flex;
	transform: translateY(1000px);
	transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
	transition-duration: 1.2s;

	&.show {
		transform: translateY(0);

        .modal-dialog {
            transform: translate(-50%, 0);

            @include media-breakpoint-up(md) {
                transform: translate(-50%,-50%);
            }
        }
	}

	.modal-dialog {
        position: absolute;
		display: flex;
		max-width: 100vw !important;
		max-height: 100vh !important;
		width: 100vw;
		height: calc(100vh - $header-height);
		margin: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);

		@include media-breakpoint-up(md) {
			width: 920px;
			height: auto;
            top: 50%;
            bottom: initial;
            transform: translate(-50%,-50%);
		}

        @media (orientation: landscape) and (height < 550px) {
            height: 95dvh;
        }

		.modal-content {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			background: $white;
			border-radius: 25px 25px 0 0;
			@include p(32px 24px);

			@include media-breakpoint-up(md) {
				border-radius: 40px;
				@include p(40px 54px);
			}

            @media (orientation: landscape) and (height < 550px) {
                @include p(20px 24px);
            }

			.modal-header {
				display: flex;
				flex-direction: column;
				@include p(0 0 20px 0);
				border: none;

				.modal-title {
					flex: 1;
					align-self: center;
					text-align: center;
					font-weight: $font-weight-bold;
					font-size: 30px;
					line-height: 120%;
					color: $dark-gray-15;
				}

				.modal-subtitle {
					@include m(24px auto 0);
					font-weight: $font-weight-normal;
					font-size: 18px;
					line-height: 150%;
					color: $dark-gray;
					text-align: center;

                    @media (orientation: landscape) and (height < 550px) {
                        @include m(14px auto 0);
                    }

					.labels {
						@include mt(24px);
						display: flex;
						flex-direction: row;
						justify-content: center;
						list-style: none;

                        @media (orientation: landscape) and (height < 550px) {
                            @include mt(14px);
                        }
					}
				}

				.modal-btn--close {
					position: absolute;
					top: 40px;
					right: 20px;
					border: none;
					background-color: transparent;
					outline: none;
					cursor: pointer;
				}
			}

			.modal-body {
				overflow: auto;
				@include p(20px 0 0 0);

				.form-btn-collapse {
					text-decoration: none;
					font-size: 14px;
					font-weight: $font-weight-bold;
					line-height: 18px;
					font-feature-settings: "clig" off, "liga" off;
					color: $dark-gray;

					&.expanded {
						.arrow {
							transform: rotate(-135deg);
							-webkit-transform: rotate(-135deg);
							border-width: 0 2px 2px 0;
						}
					}

					.arrow {
						display: inline-block;
						@include p(3px);
						-webkit-transition-duration: .25s;
						-o-transition-duration: .25s;
						transition-duration: .25s;
						transform: rotate(45deg);
						-webkit-transform: rotate(45deg);
						position: relative;
						border: solid $dark-gray;
						margin: 4px 2px 2px 7px;
						border-width: 0 2px 2px 0;
					}
				}

				.explanatory-notes-content {
					max-height: 0;
					overflow: hidden;
					transition: max-height 0.4s ease;

					&.expanded {
						max-height: 650px;
					}

					p {
						color: $dark-gray;
						font-size: 13px;
						font-weight: $font-weight-normal;
						line-height: 18px;
					}
				}
			}

		}
	}

	form {
		label {
			color: $dark-gray;
			font-weight: $font-weight-normal;
			font-size: 13px;
		}

		input, textarea {
			border: 1.3px solid $gray-b3b3;
			border-radius: 10px;

            &.with-error {
                border-color: $red;
            }
		}
	}
}
