.banner {
	display: flex;
	flex-direction: column;
	gap: 30px;

	@include media-breakpoint-up(md) {
		flex-direction: row;
		gap: 15px;

		&-vertical {
			flex-direction: column;
		}
	}


	&--round {
		@include border-radius(30px);
	}

	@each $color-name, $color-value in $colors {
		&--#{$color-name} {
			background-color: $color-value;
		}
	}

	&-image-warp {
		@include media-breakpoint-up(md) {
			@for $i from 2 through $grid-columns {
				&--col-#{$i} {
					@include make-col($i, $grid-columns);
				}
			}
		}

		&--round {
			@include border-radius(30px);
		}

		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}

	&-side {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 30px;
		gap: 30px;

		@include media-breakpoint-up(md) {
			gap: 24px;
		}

		&--round {
			@include border-radius(30px);
		}

		@each $color-name, $color-value in $colors {
			&--#{$color-name} {
				background-color: $color-value;
			}
		}

		&-title {
			font-weight: $font-weight-semibold;
			font-size: 22px;
			line-height: 130%;
			color: $text-rich-black;
		}

		&-text {
			font-weight: $font-weight-normal;
			font-size: 18px;
			line-height: 160%;
			color: $text-rich-black;
		}
	}
}
