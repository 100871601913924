.catalog-list {

    .listing-hero {
        &__bg {
            background-image: linear-gradient(rgba(0,0,0,0.5),
                rgba(0,0,0,0.5)), url('/theme/luxoft-2024/assets/images/others/catalog.jpg')!important;
        }
    }

	&__content {
		@include pt(40px);
		@include pb(3.5rem);

		@include media-breakpoint-up(lg) {
			@include pt(60px);
		}

		.catalog-list__warp {
			display: grid;
			grid-template-columns: 1fr;
			gap: 15px;
			list-style: none;
			margin: 0;
			padding: 0;

			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(2, 1fr);
				gap: 20px;
			}

			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(3, 1fr);
			}

			.catalog-list__item {
				padding: 10px;
				gap: 15px;
				background: #FFFFFF;
				border: 1.3px solid #E6E6E6;
				border-radius: 30px;
				position: relative;

				&:hover {
					background: #FFFFFF;
					box-shadow: 0px 5px 30px rgba(21, 21, 21, 0.15);
				}
			}
		}


		.category-warp {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			justify-content: flex-start;
			gap: 15px;

			a {
				text-decoration: none;
			}

			.category {
				&__img-warp {
					--section-image-size: 60px;
					--section-image-margin: 12px;

					@include media-breakpoint-up(md) {
						--section-image-size: 80px;
						--section-image-margin: 28px;
					}

					overflow: hidden;
					background: $gray-90;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex: 0 0 auto;

				}

                &__img {
                    object-fit: contain;
                    max-width: 80px;
                    max-height: 80px;
                    width: var(--section-image-size);
                    height: var(--section-image-size);
                    margin: var(--section-image-margin);
                }

				&__body {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: space-around;

					.category__header {
						.category__title {
							font-weight: $font-weight-semibold;
							font-size: 18px;
							line-height: 130%;
							align-items: center;
							letter-spacing: -0.02em;

							@include media-breakpoint-up(md) {
								font-size: 22px;
							}

							a {
								color: $dark-gray-15;
							}
						}
					}

					.category__footer {
						.category__counter {
							font-weight: 400;
							font-size: 14px;
							line-height: 140%;
							display: flex;
							align-items: center;
							letter-spacing: -0.02em;
							color: #63666A;
						}
					}
				}
			}
		}
	}
}
