@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import "custom-variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/bootstrap-utilities";
@import "bootstrap/scss/utilities/api";
@import "bootstrap/scss/bootstrap-reboot";
@import "utils/aos";
@import "general";
@import "modules/breadcrumbs";
@import "modules/popup";
@import "modules/blogs";
@import "modules/catalog";
@import "modules/category";
@import "modules/courses";
@import "modules/certificates";
@import "modules/menu";
@import "modules/footer";
@import "modules/homepage";
@import "modules/pagination";
@import "modules/pagination-buttons";
@import "modules/upcomming-courses";
@import "modules/listing-hero";
@import "modules/pages";
@import "modules/location-block";
@import "modules/partners";
@import "modules/why-luxoft-training-center";
@import "modules/banners";
@import "modules/form";
@import "modules/search";
@import "modules/promo";
@import "modules/careers-with-impact";
@import "modules/about-us-with-cols";
@import "error-blocks";
@import "modules/error-screens";
