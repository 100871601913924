.listing-hero {
	display: flex;
	min-height: 235px;
	@include mt(92px);

	@include media-breakpoint-up(md) {
		min-height: 310px;
		@include mt(8);
	}

	@include media-breakpoint-up(xxl) {
		min-height: 365px;
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		width: 100%;
		height: 370px;

		@include media-breakpoint-up(md) {
			height: 430px;
		}

		@include media-breakpoint-up(xxl) {
			height: 490px;
		}
	}

	&__content {
		position: relative;

		.hero-breadcrumbs {
			@include mt(0.8rem);
			@include mb(2rem);

			@include media-breakpoint-up(md) {
				@include mt(1.85rem);
				@include mb(3.5rem);
			}

            @include media-breakpoint-up(xxl) {
                @include mt(1.5rem);
                @include mb(4rem);
            }

			.breadcrumb-item {
				&:not(.active) {
					span {
						&:hover {
							color: $gray-c3;
						}
					}
				}

				span {
					color: $gray;
				}
			}
		}

		.hero-header {
			.header-subtitile {
				@include mt(3);
				line-height: $line-height-150;
				color: $gray;

				@include media-breakpoint-up(md) {
					@include mt(4);
					line-height: $line-height-base;
				}
			}
		}
	}
}
