.upcoming-courses {
	&__items-wrapper {
		&.desktop {
			@include media-breakpoint-down(md) {
				display: none;
			}

			.single-course {
				&__info {
					.course-details {
						.stretched-link {
							display: none;
						}
					}
				}
			}
		}

		&.mobile {
			@include media-breakpoint-up(md) {
				display: none;
			}

			.single-course {
				position: relative;
				width: 84% !important;

				&__info {
					.course-details {
						gap: initial;

						.btn-arrow-ghost--purple {
							display: none;
						}

						.stretched-link {
							line-height: 0;
							font-size: 0;
							color: transparent;
						}
					}
				}
			}
		}

	}
}

.single-course {
	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: flex-end;
	border-radius: 20px;
	padding: 20px;
	height: 320px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include m(15px auto !important);

	@include media-breakpoint-up(md) {
		@include m(15px 0);
	}

	&__info {
		background-color: $white;
		border-radius: 25px;
		display: flex;
		padding: 20px;
		gap: 12px;
		flex-direction: column;

		@include media-breakpoint-up(md) {
			height: 205px;
			justify-content: space-between;
		}

		@include media-breakpoint-up(xl) {
			height: 180px;
			gap: initial;
		}

		.course-title {
			font-weight: $font-weight-semibold;
            font-size: 16px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
		}

		.course-details {
			display: flex;
			gap: 10px;
			flex-direction: column;

			@media (width > 600px) {
				flex-direction: row;
                justify-content: space-between;
			}

			@include media-breakpoint-up(md) {
				flex-direction: column;
				justify-content: initial;
			}

			@include media-breakpoint-up(xl) {
				flex-direction: row;
				justify-content: space-between;
			}

			.course-date {
				display: flex;
				gap: 10px;
				align-items: center;

				@include media-breakpoint-up(md) {
					@include mb(10px);
				}

				@include media-breakpoint-up(xl) {
					@include mb(0);
					align-items: flex-end;
				}

                .date-text {
                    font-size: 15px;
                }
			}
		}

		a {
            font-size: 14px;
			justify-content: center;
			border-color: $gray-e6e6;
            @include p(15px 32px);
            transform: none;

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: $stretched-link-z-index;
                content: "";
            }
		}
	}
}
