@mixin link-styles() {
	color: $navy-60;
	text-decoration: none;
	transition: all 0.3s ease;

	&:hover,
	&:focus {
		color: $navy-70;
		text-decoration: underline;
	}

	&:active,
	&:visited {
		color: $navy-40;
		text-decoration: underline;
	}
}
