.error-screen {
    @include mt(202px);
    @include pb(140px);
    position: relative;

    p {
        color: $gray;
    }

    .headline {
        font-size: 140px;
        font-weight: $font-weight-bold;

        @include media-breakpoint-up(md) {
            font-size: 200px;
        }
    }

    .subheadline {
        font-size: 40px;
        font-weight: $font-weight-normal;
        line-height: 48px;

        @include media-breakpoint-up(md) {
            font-size: 60px;
            line-height: 72px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 65px;
            line-height: 78px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 70px;
            line-height: 84px;
        }
    }

    &__actions-container {
        display: flex;
        justify-content: center;
        gap: 20px;
        @include mt(40px);

        @include media-breakpoint-up(md) {
            @include mt(70px);
        }
    }
}
