.navbar-collapse {
    .menu-item {
        text-decoration: none;
        color: $gray;
        font-size: $font-size-root-md;
        transition: all 0.3s ease;
        font-size: 14px;

        &:hover {
            color: $white;
        }

        &__submenu {
            width: 149px;
            background-color: $white;
            border-radius: 20px;
            height: 0;
            padding: 0;
            position: absolute;
            top: 33px;
            -webkit-transition: height .02s cubic-bezier(0,0,0,1.01),padding .1s linear;
            transition: height .2s cubic-bezier(0,0,0,1.01),padding .1s linear;
            z-index: 10;
            left: -15px;

            .single {
                @include p(0 17px);
                visibility: hidden;
                opacity: 0;
                transition: all 0.2s ease;

                &:not(:last-child) {
                    @include mt(17px);
                    @include mb(14px);
                }

                a {
                    color: transparent;

                    &:hover {
                        color: $dark-gray-15;
                    }
                }
            }
        }
    }
}


.navbar {
    position: absolute;
    width: 100%;

    @media screen and (max-width: 1024px) {
        flex-wrap: nowrap;
    }

    #logo {
        fill: $white;

        #stroke {
            stroke: $white;
        }
    }

    &.bg-white {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.up-menu-logo {
    width: 152px;
    height: 52px;
    background: url("/theme/luxoft-2024/assets/images/logo/luxoft-training.svg") no-repeat;
}

#header.bg-white {
    border-bottom: 1px solid $gray-e6e6;

    .up-menu-logo {
        background: url("/theme/luxoft-2024/assets/images/logo/luxoft-training-purple.svg") no-repeat;
    }

    .navbar-collapse {
        .menu-item {
            color: $dark-gray;

            &:hover {
                color: $text-rich-black;
            }
        }
    }
}

.navbar {
    .mobile-menu-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mr(30px);
        @include mt(10px);

        @include media-breakpoint-up(md) {
            display: none;
        }

        .switcher {
            cursor: pointer;
            position: relative;
            height: 22px;
            width: 20px;

            &:hover {
                .item {
                    width: 20px !important;
                }
            }

            .item {
                width: 20px;
                height: 1px;
                background: $white;
                transition: 0.6s ease;
                position: absolute;

                &:nth-child(1) {
                    top: 0;
                }

                &:nth-child(2) {
                    top: 7px;
                }

                &:nth-child(3) {
                    top: 14px;
                }
            }
        }

        .text {
            span {
                font-size: 12px;
                font-weight: $font-weight-semibold;
                color: $white;
            }
        }
    }
}

.navbar-popup-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    position: fixed;
    border-radius: 20px 20px 0 0;
    @include p(20px);
    bottom: 0;
    background-color: $white;
    z-index: 1010;
    transform: translateY(1000px);
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-duration: 1.2s;

    @media (orientation: landscape) {
        height: 75dvh;
        @include p(20px 20px 0);
    }

    @include media-breakpoint-up(md) {
        display: none;
    }

    &.menu-open {
        transform: translateY(0);

        &.second-level-opened {
            transform: translateY(230px);
        }
    }

    .header-title {
        font-size: 18px;
        margin: 0;
        text-transform: uppercase;
    }

    &__first-items-container {
        width: 100%;
        transition: all 0.3s ease-in;

        @media (orientation: landscape) {
            overflow-x: hidden;
        }

        &.hidden {
            transform: translateX(-105%);
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include p(20px);

        @media (orientation: landscape) {
            @include p(10px 20px 15px 20px);
        }

        .back {
            display: none;
        }

        &.second-level-opened {
            justify-content: flex-start;
            @include p(20px 20px 20px 10px);

            .cross {
                opacity: 0;
            }

            .back {
                display: block;
                @include mr(20px);
            }
        }

        .cross {
            opacity: 1;
            transition: all 0.5s ease;
            background-color: transparent;
            padding: 0;
            margin: 0;
            border: 1px solid rgba(0, 0, 0, 0.4);
            border-radius: 10px;
            width: 32px;
            height: 32px;
        }
    }

    &-item {
        display: flex;
        padding: 15px;
        align-items: center;
        gap: 15px;
        text-decoration: none;
        height: 75px;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid $gray-90;

        &:last-child {
            border-bottom: none;
        }

        p {
            text-decoration: none;
            color: $black;
            font-size: 14px;
            margin: 0;
            padding: 0;
        }

        img {
            width: 30px;
            height: 30px;
        }
    }
}
