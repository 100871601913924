.container-breadcrumbs {
    @include media-breakpoint-up(md) {
        display: block;
    }

    .breadcrumb {
        &-item {
            font-size: 14px;

            a {
                text-decoration: none;
                color: $gray;
                font-size: 14px;

                &:hover {
                    color: $gray-21;
                }
            }
        }
    }
}
