.promo-page {
	@include mt(88px);

	color: #000;
	font-family: Open Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	.container {
		max-width: 1430px
	}

	.section {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.section:first-child {
		margin-top: 0
	}

	.section:last-child {
		margin-bottom: 25px;
	}

	.section.section-dark {
		background: #5F249F;
		color: #fff;

		a,
		i {
			color: #fff;
			font-weight: 700
		}

		button, button[type="submit"],
		div[class$="__title"] {
			color: #fff;
		}
	}

	.title {
		color: #5F249F;
		font-size: 32px;
		font-weight: 700
	}

	.sub-title {
		font-size: 18px
	}

	p {
		font-size: inherit;
		margin-top: 24px
	}

	.banner {
		height: 600px;
		max-width: 100%;
		max-height: 50vw;
		flex-shrink: 0;
		background-color: #000;
		background-image: url(./images/promo/banner.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover
	}

	.banner img {
		visibility: hidden
	}

	.cards-line {
		display: flex;
		margin: 30px 0;
		gap: 30px
	}

	@media (max-width: 767px) {
		.cards-line {
			flex-direction: column
		}
	}

	.cards-line .card {
		flex: 1 1 30%;
		display: flex;
		border: none;
		gap: 15px
	}

	@media (max-width: 767px) {
		.cards-line .card {
			flex: 1 1 100%
		}
	}

	.cards-line .card .card-text {
		color: #000;
		text-align: center;
		font-family: Open Sans;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 0 15px
	}

	.icon-catalog {
		width: 43.62px;
		height: 42.62px;
		flex-shrink: 0;
		margin: 8px 30px;
		display: inline-block;
	}

	.cards-grid {
		--cards-grid-gap: 30px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: var(--cards-grid-gap);
		margin-left: -15px;
		margin-right: -15px;
		gap: var(--cards-grid-gap)
	}

	@media (max-width: 767px) {
		.cards-grid {
			align-items: stretch
		}
	}

	.cards-grid .card {
		display: flex;
		flex: 0 1 calc(25% - var(--cards-grid-gap));
		max-width: 320px;
		min-height: 180px;
		max-height: 200px;
		padding: 20px;
		overflow: hidden;
		border: none;
		align-items: center;
		text-align: center;
		background: #F3F4F5
	}

	@media (max-width: 991px) {
		.cards-grid .card {
			min-height: 16vw;
			flex: 0 1 calc(30% - var(--cards-grid-gap))
		}
	}

	@media (max-width: 767px) {
		.cards-grid .card {
			flex: 0 1 calc(50% - var(--cards-grid-gap))
		}
	}

	.cards-grid .card .card-img {
		margin: 10px auto;
		height: 55px;
		width: auto
	}

	.cards-grid .card .card-text {
		color: #000;
		font-size: 18px;
		margin: 0 20px
	}

	.icon-catalog {
		width: 43.62px;
		height: 42.62px;
		flex-shrink: 0;
		margin: 8px 30px
	}

	.icon-next {
		width: 30px;
		height: 6.5px;
		flex-shrink: 0
	}
}
