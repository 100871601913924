.location-block {
    margin-top: 88px;

    .page-contact-us-form__image {
        position: relative;

        &__floating-text {
            color: $white;
            position: absolute;
            width: 70%;
            font-size: 22px;
            left: 15px;
            top: 50px;

            @include media-breakpoint-up(md) {
                font-size: 30px;
                left: 20px;
                top: 35px;
                width: 80%;
            }

            @include media-breakpoint-up(xl) {
                font-size: 35px;
                width: 60%;
                top: 45px;
            }
        }
    }

    &__page-title {
        h1 {
            font-weight: 400;
            font-size: 40px;
            line-height: 120%;
            letter-spacing: -0.02em;
            color: $black;
            padding: 40px 0;

            @include media-breakpoint-up(md) {
                font-size: 60px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 65px;
            }
        }
    }

    .location-block-cards {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        @include media-breakpoint-up(xl) {
            flex-wrap: nowrap;
        }

        &__card {
            border-radius: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 30px 25px;
            gap: 20px;
            border: 1.3px solid #E6E6E6;

            @include media-breakpoint-up(md) {
                width: 400px;
                height: 367px;
            }

            @include media-breakpoint-up(xl) {
                min-width: 305px;
                min-height: 390px;
            }

            .text-dark-gray {
                margin-bottom: 5px;
            }

            p {
                font-size: 15px;
            }

            &__title {
                h3 {
                    color: $purple;
                    font-size: 22px;
                }
            }

            &__location-details {
                @include media-breakpoint-up(xl) {
                    min-height: 95px;
                }
            }

            &__view-btn {
                a:hover {
                    color: $white;
                }
            }
        }
    }
}

