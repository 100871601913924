.certifications {
	&:has(.certifications__home-items) {
		@include media-breakpoint-down(md) {
			@include p(40px 1rem);
		}
	}

	&__content {
		border-radius: 30px;
		@include p(30px);
	}

	&__home-items {
		display: flex;
		gap: 25px;
		flex-direction: column;

		@include media-breakpoint-up(md) {
			flex-direction: row;
		}

		.single-certification {
			background-color: $white;
			border-radius: 20px;
			@include p(25px);
			flex-grow: 1;
  			flex-basis: initial;
			height: 280px;
			display: flex;
    		flex-direction: column;
    		justify-content: space-between;

			@include media-breakpoint-up(md) {
				flex-basis: 0;
				height: 234px;
			}

			@include media-breakpoint-up(xl) {
				height: 280px;
			}

			.certification-content {
				.certification-title {
					font-size: 18px;

					@include media-breakpoint-up(md) {
						font-size: 16px;
					}

					@include media-breakpoint-up(xl) {
						font-size: 18px;
					}
				}
			}

			.certification-image {
				text-align: right;

				figure {
					@include m(0);
				}
			}
		}
	}

	&__page-items {
		display: flex;
		gap: 18px;
		flex-direction: column;

		.single-certification {
			background-color: $gray-90;
			border-radius: 20px;
			@include p(25px);
			display: flex;
			flex-direction: column;
			gap: 20px;

			@include media-breakpoint-up(md) {
				display: -webkit-box;
			}

			.certification-image {
				background-color: $white;
				border-radius: 20px;
				display: flex;
				justify-content: center;
    			align-items: center;
				height: 200px;

				@include media-breakpoint-up(md) {
					width: 200px;
				}

				figure {
					@include m(0);
				}
			}

			.certification-content {
				display: flex;
				gap: 30px;
				flex-direction: column;

				@include media-breakpoint-up(md) {
					width: 68%;
					@include ml(30px);
				}

				@include media-breakpoint-up(xl) {
					width: 80%;
				}

				.certification-title {
					font-size: 22px;

					@include media-breakpoint-up(xl) {
                        font-size: 24px;
                    }
				}

				.certification-text {
					color: $dark-gray;
					font-size: 16px;

					@include media-breakpoint-up(md) {
                        font-size: 18px;
                    }
				}
			}
		}
	}
}
