form {
	--rows-gap: 16px;

	.form-error {
		&.hidden {
			display: none;
		}

		p {
			color: $red;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 0;
		}
	}

	.form_content {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&-body {
			display: flex;
			flex-direction: column;
            gap: 16px;

			.form-row {
				display: flex;
				flex-direction: column;
                gap: 16px;

				@include media-breakpoint-up(md) {
					flex-direction: row;
				}

				.control-box {
					display: flex;
					flex-direction: column;
					flex: 1 1 0;
					gap: 10px;

					.control-label {
						display: flex;

						&.required::after {
							content: '*';
							color: $red;
							margin-left: 2px;
						}
					}

					.form-control {
						display: flex;
					}
				}
			}
		}

		&-agreements {
			display: flex;
			flex-direction: column;
            gap: 16px;
		}

		&-footer {
			margin-top: 14px;

			button {
				font-size: 14px;
			}
		}

	}
}

.form-control {
	font-size: 16px !important;

    &:focus {
        box-shadow: none;
    }
}
