.footer {
    position: relative;
    background: $dark-gray-15;
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;

    .container {
        @include media-breakpoint-up(xl) {
            max-width: 1280px;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }
    }

    #logo {
        fill: $white;

        #stroke {
            stroke: $white;
        }
    }

    a {
        text-decoration: none;
        color: $white;
    }

    a:hover {
        color: $white;
    }

    &__content {
        display: flex;
        justify-content: flex-start;
        padding: 40px 20px 10px;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: column-reverse;
            padding: 40px 0 10px;
        }

        @include media-breakpoint-up(xl) {
            gap: 90px;
            flex-direction: row;
        }

        .col-contact {
            svg {
                width: 146px;
                height: 44px;
                max-width: none;

                @include media-breakpoint-down(md) {
                    width: 161px;
                    height: 54px;
                }
            }

            @include media-breakpoint-down(xl) {
                padding-bottom: 24px;
                border-right: none;
                order: 2;
            }

            @include media-breakpoint-down(md) {
                order: 0;
                margin-bottom: 10px;
            }
        }
    }

    &__menu {
        flex-direction: row;
        flex-wrap: wrap;
        display: none;
        gap: 45px;

        @include media-breakpoint-up(lg) {
            gap: 90px;
        }

        @include media-breakpoint-up(xl) {
            display: flex;
        }

        p {
            a {
                color: $gray;
                font-size: 14px;
            }
        }

        .menu-section {
            &__heading {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 30px;
            }

            ul {
                list-style-type: none;
                @include pl(0);
                display: flex;
                flex-direction: column;
                gap: 15px;

                li {
                    a,span {
                        color: $gray;
                        font-size: 14px;
                        font-weight: $font-weight-normal;
                        line-height: normal;

                        &:hover {
                            color: $white;
                        }
                    }
                }

                &.social-networks {
                    display: flex ;
                    flex-direction: row;
                    gap: 25px;

                    li {
                        a,span {
                            .social-youtube {
                                width: 18px;
                                height: 13px;

                            }
                            .social-facebook {
                                width: 9px;
                                height: 16px;
                            }

                            .social-twitter {
                                width: 13px;
                                height: 13px;
                            }
                            .social-linkedin {
                                width: 13px;
                                height: 13px;
                            }
                        }
                    }
                }
            }
        }

        .email-input {
            border-radius: 20px;
            padding: 10px;
            padding-left: 20px;
            margin: 20px 0;
            position: relative;
            background-color: $white;
            display: flex;
            justify-content: space-between;
            width: 100%;
            min-width: 315px;
            box-sizing: border-box;
            border: 1.5px solid transparent;

            &:focus-within {
                border: 1.5px solid $orange;
            }

            @include media-breakpoint-down(xl) {
                width: 446px;
            }

            input {
                border: none;
                width: 80%;
                height: 44px;
                color: $text-rich-black;
                font-size: 14px;
                font-weight: $font-weight-normal;
                line-height: 25px;
                &:focus-visible {
                    border: none;
                    outline: none;
                }
                caret-color: $orange;
                color: black;
            }
            button {
                padding: 10px;
                align-self: flex-end;
                order: 1;
            }
        }
    }

    &__menu-mobile {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            gap: 20px;
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }

        &__social-networks {
            padding-top: 30px;

            &__heading {
                font-weight: 600;
                font-size: 17px;

                @include media-breakpoint-up(xl) {
                    font-size: 20px;
                }
            }

            ul {
                list-style-type: none;
                display: flex;
                gap: 40px;
                padding: 20px 0;
                margin: 0;

                li {
                    a,span {
                        .social-youtube {
                            width: 23px;
                            height: 16px;

                        }
                        .social-facebook {
                            width: 11px;
                            height: 20px;
                        }

                        .social-twitter {
                            width: 16px;
                            height: 16px;
                        }
                        .social-linkedin {
                            width: 17px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .mobile-copyright {
            display: none;

            @include media-breakpoint-down(md) {
                display: block;
                margin-top: 35px;
                color: $gray;
                font-size: 13px;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 160%;
            }
        }

        .menu-accordion {
            @include p(0 !important);
            @include mx(0);
            @include my(0);
            color: $white;

            .accordion-item {

                &.mobile {
                    .footer-accordion-item {
                        background-color: transparent;
                        border: none !important;

                        .accordion-collapse {
                            .accordion-body::-webkit-scrollbar {
                                width: 2px;
                            }

                            .accordion-body::-webkit-scrollbar-track {
                                -webkit-box-shadow: inset 0 0 6px $gray-30;
                            }

                            .accordion-body::-webkit-scrollbar-thumb {
                                background-color: $gray-70;
                                outline: 1px solid $gray-70;
                            }

                            .accordion-body {
                                @include p(0);
                                height: 152px;
                                @include mt(10px);
                                @include mb(16px);
                                overflow-x: hidden;

                                &.small {
                                    height: 90px;
                                }

                                &.smallest {
                                    height: 60px;
                                }

                                ul {
                                    @include pl(0);

                                    li {
                                        @include mt(0);
                                        @include mb(.3rem);

                                        a {
                                            font-size: 15px;
                                            color: $gray-80;
                                        }
                                    }
                                }
                            }
                        }

                        #collapseInsurance {
                            .accordion-body {
                                @include media-breakpoint-up(lg) {
                                    height: 220px;
                                }
                            }
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {
                            height: auto;
                            overflow-x: initial;
                            @include ml(16px);
                        }
                    }
                }

                &.normal {
                    background-color: transparent;
                    border-top: none;
                    border-right: none;
                    border-left: none;

                    .accordion-button {
                        width: 100%;
                        display: flex;
                        position: relative;
                        font-size: 17px;
                        background-color: transparent;
                        border: none !important;
                        color: $white;
                        box-shadow: none !important;
                        font-weight: $font-weight-semibold;
                        @include py(14px);
                        @include px(0);

                        &::after {
                            content: '';
                            position: absolute;
                            right: 0;
                            background-image: url('/theme/luxoft-2024/assets/images/icons/chevron-down-gray.svg');
                            rotate: (-180deg);
                            filter: brightness(0) invert(1);
                            width: 25px;
                            height: 25px;
                        }

                        &.collapsed {
                            &::after {
                                background-image: url('/theme/luxoft-2024/assets/images/icons/chevron-down-gray.svg');
                                rotate: none;
                                filter: none;
                            }
                        }
                    }

                    .accordion-body {
                        @include p(0);

                        ul {
                            @include pl(0);

                            li {
                                @include mb(2);
                            }
                        }
                    }
                }

                &.normal:not(:last-child) {
                    border-bottom: 1px solid $gray-60;
                }
            }

            @media (max-width: 900px) {
                padding: 60px;
            }

            @media (max-width: 700px) {
                padding: 40px 20px;
            }
        }

        .menu-section {
            padding: 0 20px;

            h5 {
                font-size: 17px;
                font-weight: $font-weight-semibold;
                margin-bottom: 30px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 5px;
                }
            }

            div {
                p {
                    a {
                        color: $gray;
                        font-size: 15px;
                        font-weight: $font-weight-normal;
                        line-height: 160%;
                    }
                }
            }

            ul {
                list-style-type: none;
                @include pl(0);
                display: flex;
                flex-direction: column;
                gap: 15px;

                li {
                    a, span {
                        color: $gray;
                        font-size: 14px;
                        font-weight: $font-weight-normal;
                        line-height: normal;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }

            .mobile-copyright {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                    margin-top: 35px;
                    color: $gray;
                    font-size: 13px;
                    font-weight: $font-weight-normal;
                    line-height: 160%;
                }
            }
        }

        .email-input {
            border-radius: 20px;
            padding: 10px;
            padding-left: 20px;
            margin: 20px 0;
            position: relative;
            background-color: $white;
            display: flex;
            justify-content: space-between;
            width: 100%;
            min-width: 315px;
            box-sizing: border-box;
            border: 1.5px solid transparent;

            &:focus-within {
                border: 1.5px solid $orange;
            }

            input {
                border: none;
                width: 80%;
                height: 44px;
                font-size: 14px;
                font-weight: $font-weight-normal;
                line-height: 25px;
                &:focus-visible {
                    border: none;
                    outline: none;
                }
                caret-color: $orange;
                color: black;
            }
            button {
                padding: 10px;
                align-self: flex-end;
                order: 1;
                width: 44px;
            }
        }

        .accordion-button {
            padding: 15px 0px !important;
        }

        .accordion-body {
            padding: 20px 0;
            ul {
                margin: 0;

                li {
                    list-style-type: none;

                    &:first-child {
                        margin: 0;
                    }

                    a, span {
                        color: $gray;
                        font-size: 15px;
                        font-weight: $font-weight-normal;
                        line-height: 160%;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .social-list {
        display: flex;
        gap: 15px;

        &__item {
            width: 40px;
            height: 40px;
            background-color: transparent;
            border: none;
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 50%;

            &-icon{
                object-fit: contain;
                max-width: 40px;
                max-height: 40px;
            }
        }
    }

    .sub-footer {
        background-color: $gray-20;
        font-weight: $font-weight-normal;
        font-size: 13px;
        line-height: 130%;

        @include media-breakpoint-down(md) {
            padding: 0 20px;
            padding-bottom: 64px;
        }

        color: $gray;

        a {
            color: $gray !important;

            &:hover {
                color: $white !important;
            }
        }

        &__content {
            display: flex;
            justify-content: space-between;
            padding: 20px 0px;

            @include media-breakpoint-down(xl) {
                flex-direction: column;
            }

            @include media-breakpoint-down(md) {
                padding-top: 25px !important;
            }

            &__right {
                display: block;
                justify-content: end;
                padding: 0;

                @include media-breakpoint-down(xl) {
                    justify-content: start;
                    margin-top: 10px !important;
                    padding-top: 0 !important;
                    margin-left: 0;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                    margin-bottom: 20px;
                }

                @include media-breakpoint-between(lg, xl) {
                    width: auto;
                }
            }

            &__left {
                .horizontal-menu {
                    text-transform: uppercase;
                    font-weight: 400!important;
                    gap: 20px;
                    flex-direction: column;
                    align-items: baseline;
                    align-content: start;

                    @include media-breakpoint-up(bsm) {
                        flex-direction: row;
                    }

                    ul {
                        @include media-breakpoint-down(md) {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            list-style-type: none;
                        }
                    }
                }
                @include media-breakpoint-between(lg, xl) {
                    width: auto;
                }
                @include media-breakpoint-down(xl) {
                    margin-left: 0;
                }
                @include media-breakpoint-down(md) {
                    order: 2;
                }
                @include media-breakpoint-down(sm) {
                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .border-b {
        border-bottom: 1px solid $gray-70;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        @include mt(1.5rem);
        @include pt(1.5rem);
    }
}
