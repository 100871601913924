//
// Base styles
//

.btn {
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $font-weight-normal;
	line-height: 22.4px;
	color: $white;
	text-align: center;
	text-transform: uppercase;
	text-decoration: if($link-decoration==none, null, none);
	white-space: $btn-white-space;
	vertical-align: middle;
	cursor: if($enable-button-pointers, pointer, null);
	user-select: none;
	background-color: $purple;
	border: $btn-border-width solid transparent;
	@include button-size($btn-padding-y,
		$btn-padding-x,
		$btn-font-size,
		$btn-border-radius);
	@include transition($btn-transition);

	&:hover {
		color: $white;
		text-decoration: if($link-hover-decoration==underline, none, null);
		background-color: $bright-purple-40;
	}

	.btn-check:focus+&,
	&:focus:not(.btn-text) {
		outline: 0;
		box-shadow: $btn-focus-box-shadow;
	}

	.btn-check:checked+&,
	.btn-check:active+&,
	&:active,
	&.active {
		@include box-shadow($btn-active-box-shadow);

		&:focus {
			@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
		}
	}

	&:disabled,
	&.disabled,
	fieldset:disabled & {
		pointer-events: none;
		opacity: $btn-disabled-opacity;
		@include box-shadow(none);
	}

	@include media-breakpoint-down(md) {
		font-size: $font-size-15;
	}

    &.back-btn {
        text-transform: capitalize;
        font-size: 13px;
        @include p(15px 20px);
        display: flex;
        width: fit-content;
        align-items: center;

        &:hover {
            img {
                transform: translate(-4px, 0px);
            }
        }

        img {
            transition: transform 0.3s ease;
        }

        &--gray {
            color: $black;
            background-color: $white;
            border: 1px solid $gray-e6e6;
        }
    }
}

.btn-normal {
	padding: 15px 25px;
	color: $white;
	background-color: $purple;
	border-radius: 15px;
	text-decoration: none;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22.4px;
	border: none;
	transition: all 0.3s ease;

	&:hover {
		background-color: $bright-purple-40;
		color: $white;
	}

	@include media-breakpoint-down(md) {
		text-align: center;
		font-size: 15px;
	}

	@media (max-width: 600px) {
		width: 100%;
	}

	&.outline {
		border: 1.5px solid $purple;
		background-color: $white;
		color: $purple;

		&:hover {
			color: $white;
			background-color: $purple;
		}
	}

	@each $color-name, $color-value in $colors {
		&--#{$color-name} {
			background-color: $color-value;

			&:hover {
				@if $color-name=='rich-black' {
					background-color: $gray-21;
				}
			}

			&.outline {
				border: 1.5px solid $color-value;
				color: $color-value;

				&:hover {
					background-color: $color-value;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		text-align: center;
		font-size: 15px;
	}

	@media (max-width: 600px) {
		width: 100%;
	}
}

.btn-arrow {
	background-color: transparent;
	border-radius: 15px;
	border: 1.5px solid $white;
	padding: 10px;
	transition: all 0.3s; // Add transition here

	@include media-breakpoint-down(md) {
		background-color: $white;
		border: none;
		box-shadow: 0px 2px 5px 0px rgba(21, 21, 21, 0.08);
	}

	svg {
		stroke: $white;
		transition: all 0.3s; // Add transition here

		@include media-breakpoint-down(md) {
			stroke: $dark-gray-15;
		}
	}

	&:hover {
		background-color: $white;

		svg {
			stroke: $dark-gray-15;
			transform: rotate(45deg);
		}
	}
}

@each $color-name, $color-value in $colors {
	.btn-arrow-ghost--#{$color-name} {
        gap: 10px;
        color: $color-value;
        border: 1.5px solid $color-value;
        text-decoration: none;
        font-weight: $font-weight-semibold;
        line-height: 22.4px;
        border-radius: $btn-border-radius;
        font-size: 14px;
        @include p(15px 20px 15px 25px);
		display: flex;
		align-items: center;
		transform: rotate(0deg);
		transition: all 0.3s ease;
		background-color: transparent;
		text-transform: uppercase;
		flex-shrink: 0;

		&.text-black {
			color: $dark-gray-15;
		}

		&.text-center {
			justify-content: center;
		}

		&.px-auto {
			padding-left: initial;
			padding-right: initial;
		}

		&:hover {
			color: $white;
			background-color: $color-value;

			svg {
				transition: transform 0.5s ease;
				stroke: $white;
				transform: rotate(45deg);
			}
		}

		svg {
			stroke: $color-value;
			transition: transform 0.3s ease;
		}

		@include media-breakpoint-down(md) {
			justify-content: center;
		}

		@media (max-width: 600px) {
			width: 100%;
		}

		&.outline {
			border: 1.5px solid $color-value;
			color: $color-value;
			background-color: transparent;

			&:hover {
				background-color: $color-value;

				&.transparent {
					background-color: transparent;

					svg {
						stroke: $color-value;
					}
				}
			}
		}

		&.filled {
			color: $white;
			background-color: $color-value;

			&:hover {
				@if $color-name=='purple' {
					background-color: $bright-purple-40;
				}

				@if $color-name=='rich-black' {
					background-color: $gray-21;
				}
			}

			&.light-border {
				@if $color-name=='purple' {
					border: 1px solid $bright-purple-60;

					&:hover {
						background-color: $bright-purple-60;
					}
				}
			}

			svg {
				stroke: $white;
			}
		}
	}
}

@each $color-name, $color-value in $colors {
	.btn-arrow--#{$color-name} {
		padding: 15px 20px 15px 25px;
		gap: 10px;
		color: $white;
		border: 1.5px solid $color-value;
		border-radius: $btn-border-radius;
		text-decoration: none;
		font-size: 14px;
		line-height: 22.4px;
		display: flex;
		align-items: center;
		font-weight: $font-weight-semibold;
        text-transform: uppercase;
        flex-shrink: 0;

		&:hover {
			color: $white;
			background-color: $color-value;

			svg {
				stroke: $white;
			}
		}

		svg {
			stroke: $white;
		}

		@include media-breakpoint-down(md) {
			justify-content: center;
			font-size: 15px;
		}

		@media (max-width: 600px) {
			width: 100%;
		}
	}
}

//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}

.btn-lg {
	@include button-size($btn-padding-y-lg,
		$btn-padding-x-lg,
		$btn-font-size-lg,
		$btn-border-radius-lg);
}

.btn-sm {
	@include button-size($btn-padding-y-sm,
		$btn-padding-x-sm,
		$btn-font-size-sm,
		$btn-border-radius-sm);
}

.btn-wrapper {
	display: flex;
	align-items: center;

	&--center {
		justify-content: center;
	}

	&--left {
		justify-content: left;
	}
}

.btn-transparent {
    border: 1.5px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(7.5px);
    @include p(10px 20px);
    align-items: center;
    gap: 5px;
    border-radius: 15px;
    display: flex;
    font-size: 14px;
    color: $white !important;
    text-decoration: none;
    line-height: 19.6px;

    &:hover {
        border-color: $white;
    }
}
