.pages-hero {
    height: 80vh;
    @include pt(105px);
    position: relative;

    &.smaller {
        height: 460px;

        .pages-hero__bg {
            height: 480px;
        }
    }

    &.no-breadcrumbs {
        @include pt(70px);
    }

    .container {
        position: relative;
        height: 100%;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 85vh;
        z-index: 2;
        width: 100%;
        overflow: hidden;

        .hero-overlay {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #00000091;
            z-index: 1;
        }

        img {
            height: 100%;
            left: 50%;
            object-fit: cover;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            z-index: 0;
        }
    }

    &__breadcrumbs {
        z-index: 3;
        position: relative;

        @include media-breakpoint-up(xxl) {
            margin-top: 5px;
        }

        .container-breadcrumbs {
            .breadcrumb-item {
                a {
                    color: $gray;

                    &:hover {
                        color: $gray-c3;
                    }
                }
            }
        }
    }

    &__heading {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(15px, -50%);
        display: flex;
        flex-direction: column;
        gap: 25px;
        z-index: 3;
        width: 94%;

        @include media-breakpoint-up(md) {
            transform: translate(-50%, -50%);
            left: 50%;
            width: 90%;
        }

        @include media-breakpoint-up(xl) {
            width: 68%;
        }

        @include media-breakpoint-up(xxl) {
            width: 70%;
        }

        p,
        h1 {
            @include media-breakpoint-up(md) {
                text-align: center;
            }
        }

        p {
            color: $gray;
            font-size: 18px;
        }
    }
}

.luxoft-training-center {
    &__headline {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0 auto;
        text-align: center;

        @include media-breakpoint-up(md) {
            gap: 25px;
            width: 85%;
        }

        @include media-breakpoint-up(xl) {
            width: 65%;
        }

        p {
            color: $dark-gray;
        }
    }

    &__items {
        @include mt(4);

        &--single-item {
            height: 466px;
            border-radius: 25px;
            @include p(16px 10px 10px);
            @include mb(25px);
            overflow: hidden;
            position: relative;

            @include media-breakpoint-up(md) {
                height: 480px;
                @include mb(0);
            }

            figure {
                @include m(0);
            }

            img {
                border-radius: 25px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }

            .item-wrapper {
                height: 100%;
                z-index: 2;
                position: relative;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                &__number {
                    background-color: $white;
                    border-radius: 50%;
                    height: 24px;
                    width: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include ml(4px);

                    p {
                        font-size: 14px;
                    }
                }

                &__content {
                    background-color: $white;
                    border-radius: 25px;
                    @include p(16px);
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    @include media-breakpoint-up(md) {
                        height: 245px;
                    }

                    @include media-breakpoint-up(xl) {
                        height: 165px;
                    }

                    @include media-breakpoint-up(xxl) {
                        height: 170px;
                    }

                    .heading {
                        font-weight: $font-weight-semibold;
                        font-size: 22px;
                    }

                    p {
                        color: $dark-gray;
                        font-size: 15px;
                        line-height: 1rem;
                    }
                }
            }
        }
    }
}

.customized-corporate-solutions {
    &__headline {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0 auto;
        text-align: center;

        @include media-breakpoint-up(md) {
            gap: 25px;
            width: 90%;
        }

        @include media-breakpoint-up(xl) {
            width: 72%;
        }

        p {
            color: $dark-gray;
        }
    }

    &__items {
        @include mt(4);

        &.desktop {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &.mobile {
            @include media-breakpoint-up(md) {
                display: none;
            }

            .splide {
                &__slide {
                    width: 86% !important;
                }
            }
        }

        &--single-item {
            border-radius: 30px;
            border: 1px solid $gray-e6e6;
            @include p(25px);
            display: flex;
            flex-direction: column;
            gap: 40px;

            @include media-breakpoint-up(md) {
                height: 255px;
                @include mb(20px !important);
            }

            @include media-breakpoint-up(xl) {
                height: 315px;
            }

            @include media-breakpoint-up(xxl) {
                height: 325px;
            }

            .item-content {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .heading {
                    font-weight: $font-weight-semibold;
                    font-size: 22px;
                    color: $purple;

                    @include media-breakpoint-up(xl) {
                        min-height: 54px;
                    }
                }

                .text {
                    color: $dark-gray;
                    font-size: 15px;
                    line-height: 1rem;
                }
            }
        }
    }
}

.our-mission {
    &__headline {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0 auto;
        text-align: center;

        @include media-breakpoint-up(md) {
            gap: 25px;
            width: 90%;
        }

        @include media-breakpoint-up(xl) {
            width: 72%;
        }

        p {
            color: $dark-gray;
        }
    }

    &__why-choose {
        background-color: $purple;
        border-radius: 30px;
        @include p(55px 45px);

        .btn-arrow-ghost--white {
            &.outline {
                &:hover {
                    background-color: $white;
                    color: $text-rich-black;

                    svg {
                        stroke: $text-rich-black;
                    }
                }
            }
        }

        .header-btn {
            @include media-breakpoint-down(xl) {
                display: none !important;
            }
        }

        .footer-btn {
            @include mt(6);

            @include media-breakpoint-up(md) {
                @include mt(8);
            }

            @include media-breakpoint-up(xl) {
                display: none !important;
            }
        }

        &--items {
            border-radius: 20px;
            border: 1px solid #784eaa;
            @include p(10px 20px);
            @include mt(4);

            @include media-breakpoint-up(md) {
                height: 100%;
                @include p(30px);
            }

            @include media-breakpoint-up(xl) {
                @include mt(0);
            }

            .why-choose-row {
                &:first-child {
                    @include media-breakpoint-up(md) {
                        @include mb(4);
                    }
                }

                .why-choose-item {
                    display: flex;
                    gap: 12px;
                    @include m(14px 0);

                    @include media-breakpoint-up(md) {
                        @include m(0);
                    }

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }

                    .item-icon {
                        display: -webkit-box;
                    }

                    .item-text {
                        width: 90%;

                        @include media-breakpoint-up(xl) {
                            width: 80%;
                        }

                        p {
                            font-weight: $font-weight-semibold;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

.page-contact-us-form {
    &__headline {
        margin: 0 auto;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 90%;
        }

        @include media-breakpoint-up(xl) {
            width: 92%;
        }

        p {
            color: $dark-gray;
        }
    }

    &__image {
        border-radius: 30px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/theme/luxoft-2024/assets/images/others/form-image.png');
        height: 335px;

        @include media-breakpoint-up(md) {
            height: 474px;
        }

        @include media-breakpoint-up(xl) {
            height: 712px;
        }

        &:has(img) {
            background-image: none;
            overflow: hidden;
            position: relative;
        }

        figure {
            @include m(0);
        }

        img {
            border-radius: 30px;
            position: absolute;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .custom-image {
        background-image: url('/theme/luxoft-2024/assets/images/others/contact-us-custom.png');
    }

    &__form {
        border-radius: 30px;
        border: 1px solid $gray-e6e6;
        @include p(12px 25px);
        @include mt(30px);

        @include media-breakpoint-up(md) {
            @include mt(0);
        }

        .form-error {
            @include mt(8px);
        }

        .form-group {
            @include m(6px 0);
        }

        label {
            color: $dark-gray;
            font-size: 14px;
            font-weight: $font-weight-normal;
            line-height: 18px;

            &.required {
                &::after {
                    content: " *";
                    color: #F00;
                    font-weight: normal;
                }
            }
        }

        .consent {
            label {
                font-size: 13px;
            }
        }

        textarea {
            &.custom-contact-form-inputs {
                min-height: 120px !important;
                max-height: 200px !important;
            }
        }

        .form-btn-collapse {
            text-decoration: none;
            font-size: 14px;
            font-weight: $font-weight-bold;
            line-height: 18px;
            font-feature-settings: "clig" off, "liga" off;
            color: $dark-gray;

            &.expanded {
                .arrow {
                    transform: rotate(-135deg);
                    -webkit-transform: rotate(-135deg);
                    border-width: 0 2px 2px 0;
                }
            }

            .arrow {
                display: inline-block;
                @include p(3px);
                -webkit-transition-duration: .25s;
                -o-transition-duration: .25s;
                transition-duration: .25s;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                position: relative;
                border: solid $dark-gray;
                margin: 4px 2px 2px 7px;
                border-width: 0 2px 2px 0;
            }
        }

        .explanatory-notes-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.4s ease;

            &.expanded {
                max-height: 650px;
            }

            p {
                color: $dark-gray;
                font-size: 13px;
                font-weight: $font-weight-normal;
                line-height: 18px;
            }
        }

        .custom-contact-form-inputs {
            border-radius: 10px;
            @include p(8px 10px 8px 15px);
            color: $black;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.28px;
            height: 40px;

            &.with-error {
                border-color: $red;
            }
        }
    }
}

.cta-section {
    @include mb(2);
    @include mt(2);

    .cta-section__cta-banner {
        h3 {
            font-size: 22px;
        }
    }

    .btn-arrow-ghost--white {
        &.outline {
            &:hover {
                background-color: $white;
                color: $text-rich-black;

                svg {
                    stroke: $text-rich-black;
                }
            }
        }
    }

    &--purple {
        .cta-section__cta-banner {
            background-color: $purple;

            a {
                border: 1.5px solid $white;

                &:hover {
                    background-color: $bright-purple-40;
                }
            }
        }
    }

    &--light-gray {
        .cta-section__cta-banner {
            background-color: $gray-90;

            h3 {
                color: $dark-gray-15;
            }

            .btn-normal {
                border: 1.5px solid $dark-gray-15;
                background-color: transparent;
                color: $dark-gray-15;
            }
        }
    }

    &__cta-banner {
        display: flex;
        padding: 40px;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: $dark-gray-15;
        justify-content: space-between;

        @include media-breakpoint-down(xl) {
            padding: 30px;
        }

        @include media-breakpoint-down(md) {
            @include p(30px);
            flex-direction: column;
            gap: 30px;
        }

        h3 {
            color: $white;

            @include media-breakpoint-down(xl) {
                font-size: 22px;
                font-weight: 600;
                line-height: 130%;
                letter-spacing: -0.44px;
            }

            @include media-breakpoint-down(md) {
                font-size: 20px;
                font-weight: 600;
                line-height: 140%;
                letter-spacing: -0.4px;
            }
        }

        a {
            &.btn-normal {
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
    }
}

.corporate-training-solutions {
    @include media-breakpoint-down(md) {
        @include p(40px 1rem);
    }
}

.develop-grow-win {
    &__image {
        background-size: cover;
        background-repeat: no-repeat;
        height: 280px;
        border-radius: 30px;
        background-position: center;
        background-image: url('/theme/luxoft-2024/assets/images/others/develop-grow-win-small.png');

        @include media-breakpoint-up(md) {
            background-image: url('/theme/luxoft-2024/assets/images/others/develop-grow-win-medium.png');
        }

        @include media-breakpoint-up(xl) {
            background-image: url('/theme/luxoft-2024/assets/images/others/develop-grow-win-large.png');
        }

        &:has(img) {
            background-image: none;
            overflow: hidden;
            position: relative;
        }

        figure {
            @include m(0);
        }

        img {
            border-radius: 30px;
            position: absolute;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    &__content {
        background-color: $gray-90;
        border-radius: 30px;
        @include p(25px);
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: 280px;
        @include mt(30px);

        @include media-breakpoint-up(md) {
            @include mt(0);
        }

        .develop-btn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 164px;

            @include media-breakpoint-up(md) {
                height: 162px;
            }

            .btn-normal {
                @media (width > 600px) {
                    width: max-content;
                }
            }
        }

        .develop-heading {
            font-weight: $font-weight-semibold;
            font-size: 22px;
        }

        .develop-text {
            font-size: 18px;
        }
    }
}

.training-partners-companies {
    &__items {
        .single-item {
            @include media-breakpoint-down(md) {
                width: 35% !important;
            }
        }

        .splide {
            &__pagination {
                display: none;
            }
        }
    }

    .section-title {
        font-size: 18px;
        font-weight: $font-weight-semibold;
    }
}
