@mixin blog-item() {
    .blog-post__standard-item {
        position: relative;
        @include mb(24px);
        display: -webkit-box;
        transition: all .3s ease;

        @include media-breakpoint-up(md) {
            @include p(15px);
            border-radius: 30px;
            border: 1px solid $gray-e6e6;
            @include mb(0);
            display: block;
        }

        &:hover {
            box-shadow: 0 5px 30px 0 hsla(0, 0%, 8%, .1);
        }

        &--image {
            overflow: hidden;
            height: 70px;
            width: 70px;
            position: relative;

            @media (width > 400px) {
                height: 72px;
                width: 72px;
            }

            @include media-breakpoint-up(md) {
                height: 180px;
                width: auto;
            }

            figure {
                @include m(0);
            }

            img {
                @include border-radius(10px);
                position: absolute;
                height: 100%;
                width: 100%;
                object-fit: cover;

                @include media-breakpoint-up(md) {
                    @include border-radius(28px);
                }
            }
        }

        &--content {
            @include pl(12px);
            width: 81%;

            @media (width > 400px) {
                @include pl(20px);
            }

            @media (width > 550px) {
                width: 85%;
            }

            @media (width > 750px) {
                width: 88%;
            }

            @include media-breakpoint-up(md) {
                @include px(0);
                @include pt(20px);
                @include pb(5px);
                width: 100%;
            }

            .blog-tag {
                background-color: $purple-ead;
                border-radius: 4px;
                color: $purple;
                font-size: 12px;
                @include mb(5px);

                @include media-breakpoint-up(md) {
                    @include mb(15px);
                }

                &.bigger-tag {
                    @media (width <= 465px) {
                        font-size: 9px;
                    }
                }
            }

            .blog-date {
                color: $dark-gray-15;
                font-size: 13px;
                @include mb(20px);
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }

            .blog-text {
                font-size: 15px;
                color: $dark-gray;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 40px;

                @include media-breakpoint-up(md) {
                    -webkit-line-clamp: 3;
                    height: 57px;
                }
            }

            .blog-title {
                color: $dark-gray-15;
                text-decoration: none;
                font-size: 18px;
                @include mb(10px);
                font-weight: $font-weight-semibold;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                line-height: 130%;
                letter-spacing: -0.25px;

                @include media-breakpoint-up(md) {
                    @include mb(15px);
                    font-size: 22px;
                    -webkit-line-clamp: 3;
                    height: 87px;
                }

                @include media-breakpoint-up(xl) {
                    -webkit-line-clamp: 2;
                    height: 58px;
                }
            }
        }
    }
}
