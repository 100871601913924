@mixin dropdown-search() {
    width: 240px;
    height: 55px;

    .dropdown-face {
        width: 100%;
        display: flex;
        height: 55px;
        padding: 15px;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        flex: 1 0 0;
        border-radius: 15px;
        background-color: transparent;
        border: 1px solid $gray-e6e6;

        @include media-breakpoint-down(xl) {
            @include p(14px 15px);
        }

        img {
            transition: all 0.2s ease;
        }

        &.show {
            color: $dark-gray-15;
        }

        &.show {
            img {
                transform: rotate(180deg);
            }
        }
    }

    .dropdown-menu::-webkit-scrollbar {
        width: 2px;
    }

    .dropdown-menu::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $gray-999;
    }

    .dropdown-menu::-webkit-scrollbar-thumb {
        background-color: $gray-70;
        outline: 1px solid $gray-70;
    }

    .dropdown-menu {
        width: 100%;
        border: none;
        box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
        -webkit-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
        -moz-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
        @include p(0 20px);
        overflow-x: hidden;
        max-height: 300px;

        @media (max-height: 710px) and (min-width: 1024px) {
            max-height: 220px;
        }

        @media (max-height: 710px) and (min-width: 1440px) {
            max-height: 255px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        li {
            cursor: pointer;
            @include m(22px 0);
            display: flex;
            justify-content: space-between;
            position: relative;
            font-size: 14px;

            label {
                width: 100%;
                cursor: pointer;
            }

            input[type="checkbox"] {
                cursor: pointer;
                -webkit-appearance: none;
                appearance: none;
                background-color: transparent;
                margin: 0;
                width: 24px;
                height: 24px;
                border: 1px solid $gray-ccc;
                border-radius: 5px;
                display: grid;
                place-content: center;

                &::before {
                    content: url("/theme/luxoft-2024/assets/images/icons/checkbox-checked-purple.svg");
                    width: 24px;
                    height: 24px;
                    transform: scale(0);
                    transform-origin: center;
                    transition: 120ms transform ease-in-out;
                }

                &:checked {
                    &::before {
                        border: none;
                        transform: scale(1);
                    }
                }
            }
        }
    }
}
