:root {
	--bs-font-sans-serif: "Open Sans", sans-serif;
	letter-spacing: 0;

	font-size: $font-size-root-sm;

	@include media-breakpoint-up(md) {
		font-size: $font-size-root-md;
	}

	@include media-breakpoint-up(xxl) {
		font-size: $font-size-root-xl;
	}
}


body {
	font-size: $font-size-base;
	background-color: $dark-gray-15;
}

h1 {
	font-weight: $font-weight-normal;
	line-height: 120%;
	font-size: 40px;

	@include media-breakpoint-up(md) {
		font-size: 60px;
	}

	@include media-breakpoint-up(xl) {
		font-size: 65px;
	}

	@include media-breakpoint-up(xxl) {
		font-size: 70px;
	}
}

h2 {
	font-size: 60px;
	font-weight: $font-weight-semibold;
	line-height: 120%;

	@include media-breakpoint-down(xxl) {
		font-size: 55px;
	}

	@include media-breakpoint-down(xl) {
		font-size: 50px;
	}

	@include media-breakpoint-down(md) {
		font-size: 35px;
	}
}

h3 {
	font-size: 30px;
	font-weight: $font-weight-bold;
	line-height: 120%;

	@include media-breakpoint-down(xxl) {
		font-size: 30px;
	}

	@include media-breakpoint-down(xl) {
		font-size: 30px;
	}

	@include media-breakpoint-down(md) {
		font-size: 20px;
	}
}

h4 {
	font-size: 25px;
	font-weight: $font-weight-bold;
	line-height: 120%;

	@include media-breakpoint-down(xxl) {
		font-size: 25px;
	}

	@include media-breakpoint-down(xl) {
		font-size: 25px;
	}

	@include media-breakpoint-down(md) {
		font-size: 20px;
	}
}

h5 {
	font-size: 25px;
	font-weight: $font-weight-bold;
	line-height: 120%;
	letter-spacing: 0.5px;

	@include media-breakpoint-down(md) {
		font-size: 18px;
		font-weight: $font-weight-semibold;
		line-height: 130%;
		letter-spacing: 0.36px;
	}
}

.text-14 {
	font-size: 14px !important;
}

.text-15 {
	font-size: 15px !important;
}

.text-16 {
	font-size: 16px !important;
}

.text-18 {
	font-size: 18px !important;
}

.text-20 {
	font-size: 20px !important;
}

.text-22 {
	font-size: 22px !important;
}

.text-24 {
	font-size: 24px !important;
}

.text-26 {
	font-size: 26px !important;
}

.text-28 {
	font-size: 28px !important;
}

.underline {
	text-decoration: underline;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.align-justify {
	text-align: justify;
}

.align-left {
	text-align: left;
}

img,
svg {
	max-width: 100%;
}

.edit-page-btn {
	z-index: 10;
	position: fixed;
	width: 50px;
	height: 50px;
	bottom: 40px;
	right: 40px;
	background-color: $purple;
	@include border-radius(15px);
	text-align: center;
	box-shadow: 2px 2px 3px $purple;

	&__image {
		width: 30px;
		filter: invert(1);
		@include mt(9px);
		@include ml(3px);
	}
}

.default-ul {
	list-style: none;
	font-size: 15px;
	line-height: 24px;

	li {
		position: relative;
		@include mb(4px);

		&::before {
			content: "\25CF";
			display: inline-block;
			width: 1.2em;
			margin-left: -1.2em;
			font-size: 12px;
			position: absolute;
			top: 2px;
		}
	}
}

.white-section {
	position: relative;
	z-index: 4;
	background-color: $white;
	border-radius: 40px;
	@include p(40px 0);

	@include media-breakpoint-down(md) {
		border-radius: 25px;
		padding: 40px 0px;
	}

	p {
		a {
			@include link-styles();
		}
	}
}

@include media-breakpoint-down(md) {
	.container {
		@include p(0 20px);
	}
}

.section-container {
	@include p(40px 0);

	&__gray {
		background-color: $gray-90;
	}

	&__purple {
		background-color: $purple;
	}

	&__headline {
		display: flex;
        flex-direction: column;
        gap: 20px;
		margin: 0 auto;

		@include media-breakpoint-up(md) {
			gap: 25px;
            width: 90%;
			text-align: center;
		}

		@include media-breakpoint-up(xl) {
            width: 72%;

			&.larger {
				width: 90%;
			}
        }

		p {
			line-height: 150%;
            color: $dark-gray;
        }
	}
}

.btn-center {
	display: flex;
	justify-content: center;
}

.body-m-regular {
	font-size: 14px;
	line-height: 140%;
	font-weight: $font-weight-normal;
	letter-spacing: -0.28px;
}

.splide__pagination--custom {
	gap: 10px;
	padding-top: 15px !important;
	padding-bottom: 20px !important;
	align-items: center;
    @include pl(0);

	li {
		margin: 0 !important;
		display: flex !important;
		align-items: center !important;
	}

    &__item {
        margin: 0;
        padding: 0;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        border: 2px solid transparent;
        background-color: $gray;

        &.is-active {
            background-color: $white;
            border: 2px solid $gray;
            padding: 3px;
        }
    }
}


.bg-gradient {
    border-radius: 50%;
    opacity: 0.45;
    background: var(--csk-5-f-249-f-500, $purple);
    filter: blur(200px);
    position: absolute;
    top: 200px;
    width: 100%;
    height: 600px;
    z-index: -1;

    @include media-breakpoint-down(md) {
        position: absolute;
        top: 50px;
        border-radius: 600px;
        opacity: 0.55;
        background: var(--csk-5-f-249-f-500, $purple);
        filter: blur(100px);
    }
}

.fw-semibold {
    font-weight: $font-weight-semibold;
}