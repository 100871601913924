.small-hero-no-image {
    height: 88px;
}

.search {
    &.courses-list {
        .courses-list__item {
            .course-item {
                @include media-breakpoint-up(md) {
                    grid-template-columns: 2fr 1fr;
                }

                @include media-breakpoint-up(xl) {
                    grid-template-columns: 3fr 1fr;
                }

                &__description-list {
                    @include media-breakpoint-up(md) {
                        grid-template-columns: 1fr 2fr 1fr;
                    }

                    @include media-breakpoint-up(xl) {
                        grid-template-columns: 1fr 1fr 1fr;
                    }

                    .description-item {
                        &--price {
                            @include media-breakpoint-up(md) {
                                order: 1;
                            }
                        }

                        &--labels {
                            order: initial;
                            grid-column: initial;
                        }
                    }
                }

                &__body {
                    gap: 25px;
                }

                &__img-warp {
                    display: none;
                }

                &__actions {
                    @include media-breakpoint-up(md) {
                        padding-left: 20px;
                    }
                }
            }
        }
    }

    &__listing-summary {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__options {
            display: flex;
            gap: 10px;

            .dropdown {
                .dropdown-face {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    padding: 8px 10px;
                    padding-left: 15px;
                    border-radius: 10px;
                    border: 1.5px solid $gray-e6e6;
                    background-color: $white;
                    color: $dark-gray-15;

                    .grey-text {
                        color: $gray;
                    }
                }

                .dropdown-menu {
                    border-radius: 15px;
                    box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
                    width: 100%;
                    min-width: unset;
                    padding: 0;
                    overflow: hidden;
                    z-index: 900;

                    .dropdown-item {
                        padding: 15px 20px;
                        color: $dark-gray-15;

                        &:hover {
                            background-color: $gray-f9;
                        }
                    }

                    &:nth-child(2) {
                        width: 160px;
                    }
                }

                &.recent {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &.search-per-page {
                    &.desktop {
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }

                    &.mobile {
                        @include media-breakpoint-up(md) {
                            display: none;
                        }
                    }
                }
            }
        }

        .search-result {
            font-size: 18px;

            b {
                font-weight: $font-weight-semibold;
            }
        }
    }
}

.mobile-filters-panel {
    display: flex;
    flex-direction: column;
    opacity: 0;
    position: fixed;
    bottom: 0;
    z-index: 202;
    transform: translateY(100%);
    background-color: $white;
    width: 100%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    left: 0;
    height: 459px;

    &.show {
        opacity: 1;
        transform: translateY(0);

        @include media-breakpoint-up(md) {
            opacity: 0;
            transform: translateY(100%);
        }
    }

    &__list {
        border-radius: 15px;
        border: none;
        min-width: unset;
        @include p(0 20px);

        &.checkbox-list {
            @include p(0);

            ul {
                li {
                    @include p(13px 20px);

                    &:has(input[type="checkbox"]:checked) {
                        color: $purple;
                    }
                }
            }
        }

        .filter-search-results {
            height: 275px;
            overflow-x: auto;
        }

        ul {
            list-style: none;
            @include pl(0);

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include p(15px 0);

                label {
                    width: 100%;
                    cursor: pointer;
                    font-size: 15px;
                }

                input[type="radio"],
                input[type="checkbox"] {
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: transparent;
                    place-content: center;

                    &::before {
                        content: url("/theme/luxoft-2024/assets/images/icons/check-purple.svg");
                        width: 24px;
                        height: 24px;
                        opacity: 0;
                    }

                    &:checked {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__head {
        display: flex;
        @include p(20px);
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 18px;
            font-weight: $font-weight-semibold;
            line-height: 130%;
            letter-spacing: 0.36px;
            text-transform: uppercase;
            color: $dark-gray-15;
        }

        button {
            @include p(4px);
            border-radius: 10px;
            border: 1px solid $gray-e6e6;
            background-color: transparent;
        }
    }

    &__close {
        padding: 20px;
        transition: all 0.3s ease-in;

        &.hidden {
            transform: translateX(-105%);
        }
    }
}
