@mixin filters-chips() {
    display: flex;
    gap: 10px;
    @include mt(8px);
    flex-wrap: wrap;

    &--chip {
        text-align: center;
        background-color: $light-purple;
        border-radius: 10px;
        @include pl(14px);
        height: 44px;
        display: flex;
        align-items: center;
        gap: 2px;
        transition: all 0.3s ease;
        flex-shrink: 0;

        &.bigger-tag {
            height: 38px;
        }

        &:hover {
            background-color: #decbf5;

            button {
                img {
                    filter: brightness(0) invert(0);
                }
            }
        }

        p {
            font-size: 14px;
            color: $purple;

            &.bigger-tag {
                @media (width <= 465px) {
                    font-size: 11px;
                }
            }
        }

        button {
            font-size: 14px;
            color: $gray;
            border: none;
            background-color: transparent;
        }

        &.counter-chip {
            background-color: transparent;
            border: 1px solid $gray-e6e6;
            @include p(0 14px);
        }
    }
}
